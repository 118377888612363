<script>
import { mapState } from 'vuex';
import ChildSupportCalculations from '../ChildSupport/ChildSupportCalculations.vue';
export default {
	components: { ChildSupportCalculations },
	computed: {
		...mapState(['agreement']),
	},
};
</script>

<template>
	<div>
		<child-support-calculations :agreement="agreement" />
	</div>
</template>
