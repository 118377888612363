<script>
import { mapState } from 'vuex';
import { BIconExclamationTriangleFill } from 'bootstrap-vue';
// import Editor from '@tinymce/tinymce-vue';

import generateDocument from '@/lib/agreement/generate';
import { analytics } from '../firebase';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {trialActiveForClients} from '@/mixins/roleLevelPermissions';

// const VUE_APP_TINY_MCE_API_KEY = process.env.VUE_APP_TINY_MCE_API_KEY;

export default {
	props: [
		'agreementData'
	],
	components: {
		// editor: Editor,
		BIconExclamationTriangleFill,
	},
	computed: {
		...mapState(['agreement']),
	},
	mounted() {
		analytics.logEvent('screen_view', {
			firebase_screen: 'preview',
			firebase_screen_class: 'PreviewWrapper',
		});
	},
	data() {
		return {
			error: true,
			// VUE_APP_TINY_MCE_API_KEY,
			editor: Editor,
			editorConfig: {
                    // The configuration of the editor.
					readOnly: true,
					htmlSupport: {
						allow: [
							{
								name: 'div',
								attributes: true,
								classes: true,
								id:true
							},
							{
								name: 'span',
								attributes: true,
								classes: true
							},
							{
								name: 'p',
								attributes: true,
								classes: true
							},
							{
								name: 'li',
								attributes: true,
								classes: true
							},
							{
								name: 'ol',
								attributes: true,
								classes: true 
							},
							{
								name: 'b',
								attributes: true,
								classes: true 
							},
							{
								name: 'i'
							},
							{
								name: 'table',
								attributes: true,
								classes: true,
							},
							{
								name: 'tbody',
								attributes: true,
								classes: true ,
							},
							{
								name: 'tr',
								attributes: true,
								classes: true,
							},
							{
								name: 'td',
								attributes: true,
								classes: true,
							}
						]
					},
					autoParagraph :false,
					fillEmptyBlocks : false,
					highlight: {
						options: [
							{
								model: 'greenMarker',
								class: 'marker-green',
								title: 'Green marker',
								color: 'rgb(25, 156, 25)',
								type: 'marker'
							},
							{
								model: 'yellowMarker',
								class: 'marker-yellow',
								title: 'Yellow marker',
								color: '#cac407',
								type: 'marker'
							}
						]
					},
					table: {
						tableProperties: {
							// The default styles for tables in the editor.
							// They should be synchronized with the content styles.
							defaultProperties: {
								borderStyle: 'solid',
								borderColor: 'hsl(90, 75%, 60%)',
								borderWidth: '3px',
								alignment: 'left',
								width: '550px',
								height: '450px'
							},
							// The default styles for table cells in the editor.
							// They should be synchronized with the content styles.
							tableCellProperties: {
								defaultProperties: {
									horizontalAlignment: 'center',
									verticalAlignment: 'bottom',
									padding: '10px'
								}
							}
						}
					},
					toolbar: [],
            },
			filteredContents:''
    
		};
	},
	asyncComputed: {
		previewContents: {
			watch: ['agreement'],
			async get() {
				this.error = false;
				if (!this.agreement.id) return;
				try {
					return this.generateDocument();
				} catch (error) {
					if (process.env.NODE_ENV === 'development') {
						console.error(error);
					}
					this.error = true;
				}
			},
		},
	},
	methods: {
		generateDocument() {
			return generateDocument({
				agreementData: this.agreementData,
			});
		},
		filterContent(content) {
			if (trialActiveForClients()) {
				const index = content.indexOf("<template id='free-trial-end'></template>");
				if (index !== -1) {
					return content.substring(0, index);
				}
			}
			return content;
		},
		trialActiveForClients

	},
	watch: {
		previewContents() {
			this.filteredContents = this.filterContent(this.previewContents);
		},
  },

};
</script>

<template>
	<div class="preview" :class='{"unselectable": trialActiveForClients()}'>
		<b-card class="mt-2 mb-0 custom-header-wrapper">
			<h4 slot="header" class="section-heading">Preview</h4>
		</b-card>
		
		<!-- <div v-if="previewContents" v-html="previewContents"></div> -->

		<ckeditor
			v-if="previewContents"
			:editor="editor"
			v-model="filteredContents"
			:config="editorConfig"
			:disabled="true"
		></ckeditor>
		<div id="preview-paywall" class="paywall text-center mt-5" v-if="trialActiveForClients()">
			<h2 class="text-navy">Move Forward with Divii and Create Your Full Separation Agreement</h2>
			<div class="d-flex  justify-content-center">
				<p class="mb-4 blockquote mt-3 col-11">Get unlimited access to our education, calculations, and automated drafting.  Your completed Separation Agreement is only a few steps away.</p>
			</div>
			<router-link :to="{ name: 'subscription' }" tag="b-button" class="btn-primary btn-lg mt-4 col-5">
				Continue
    		</router-link>

		</div>

		<!-- <editor
			v-if="previewContents"
			v-model="previewContents"
			:api-key="VUE_APP_TINY_MCE_API_KEY"
			:init="{
				contenteditable: false,
				readonly: true,
				menubar: ' ',
				plugins: [' '],
				toolbar: ' ',
			}"
			:inline="true"
			:disabled="true"
		/> -->
		<div
			v-if="error"
			class="
				text-center
				w-100
				d-flex
				justify-content-center
				align-items-center
			"
			style="height: 500px"
		>
			<b-card
				border-variant="danger"
				header="Something Went Wrong"
				header-border-variant="danger"
				header-text-variant="danger"
				align="center"
			>
				<b-card-text>
					<b-icon-exclamation-triangle-fill
						scale="2"
						variant="warning"
					/>
				</b-card-text>
				<b-card-text>
					This is embarrassing, something has gone wrong.
				</b-card-text>
				<b-card-text>
					Please let us know so we can fix this asap.
				</b-card-text>
			</b-card>
		</div>
	</div>
</template>

<style>
.paywall{
	height: 45vh;
}

.free-trial-last-visible-para {
    position: relative; 
}

.free-trial-last-visible-para::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0; 
    height: 5rem;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 92%);
}

.unselectable .ck-editor__editable_inline{
	border-bottom: 0 !important;
}
</style>