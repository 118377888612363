<script>
import range from 'lodash/range';
import { getYear } from 'date-fns';
import dayOptions from '@/lib/dayOptions';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data() {
		const year = getYear(new Date());

		return {
			dayOptions,
			yearOptions: range(year, year + 5),
			isViewMode : !hasEditPermission('section-spousal-support') 
		};
	},
	computed: {
		spousalSupport() {
			if(this.agreementData.properties?.length == 1 && this.agreementData.properties[0].address)
			this.agreementData.spousalSupport.payableFromWhichProperty = this.agreementData.properties[0].address['street']
			return this.agreementData.spousalSupport;
			// return this.agreementData.spousalSupport =  this.agreementData.spousalSupport.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.spousalSupport 
			// 	: {...this.agreementData.spousalSupport, additionalClausesToRecitals: []};
		},
		childSupport() {
			return this.agreementData.childSupport;
		},
		hasFamilyResidence() {
			return this.agreementData.agreement.propertyRecitals.includes(
				'Family Residence'
			);
		},
		lumpsumSpousalSupportPaymentOptions() {
			let paymentOptions = [];
			const propertyRecitals = (section) =>
				this.agreementData.agreement.propertyRecitals &&
				this.agreementData.agreement.propertyRecitals.length &&
				this.agreementData.agreement.propertyRecitals.includes(section);
			// if (propertyRecitals('Family Residence')) {
			// 	paymentOptions = [
			// 		...paymentOptions,
			// 		{
			// 			text: 'Paid as part of the transfer or sale of the Family Residence',
			// 			value: 'familyResidence',
			// 		},
			// 	];
			// }
			// if (propertyRecitals('Additional Real Property')) {
			// 	paymentOptions = [
			// 		...paymentOptions,
			// 		{
			// 			text: 'Paid as part of the transfer or sale of Additional Real Property',
			// 			value: 'additionalProperty',
			// 		},
			// 	];
			// }
			if (propertyRecitals('Family Residence') || propertyRecitals('Additional Real Property')) {
				paymentOptions = [
					...paymentOptions,
					{
						text: 'Factored into the Equalization Payment',
						value: 'factoredInEqPayment',
					},
				];
			}

			return [
				...paymentOptions,
				{ text: 'Paid when the Agreement is signed', value: 'signed' },
			];
		},
		additionalPropertyChoices() {
			return this.agreementData.properties.map((property, index) => (
					{
						text:  this.getAddressText(property.address),
						value: `${property.address['street']}`,
					}
			));
		},
	},
	methods:{
		getAddressText(address){
			 let formattedAddress = '';
			 formattedAddress = address['street'] ? `${address['street']},` : '';
			 formattedAddress += address['city'] ? `${address['city']},` : '';
			 formattedAddress += address['province'] ? `${address['province']}` : '';
			 return formattedAddress;
		}
	},
	mounted(){
		this.agreementData.spousalSupport =  this.agreementData.spousalSupport.hasOwnProperty('additionalClausesToRecitals') 
			? this.agreementData.spousalSupport 
			: {...this.agreementData.spousalSupport, additionalClausesToRecitals: []};
	},
	watch: {
		'spousalSupport.endPoint': function(newVal) {
			if (newVal === 'Indefinitely' && !this.spousalSupport.endingCircumstances.includes('Indefinitely')) {
				this.spousalSupport.endingCircumstances = ['Indefinitely'];
			} else if (newVal !== 'Indefinitely' && this.spousalSupport.endingCircumstances.includes('Indefinitely')) {
				this.spousalSupport.endingCircumstances = this.spousalSupport.endingCircumstances.filter(circumstance => circumstance !== 'Indefinitely');
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Spousal Support Details</h4>

		<radio-group
			v-model="spousalSupport.paymentType"
			select
			reset-undefined
			input-cols="8"
			label="Is Spousal Support going to be paid or waived?"
			:options="[
				{ value: 'periodic', text: 'Paid periodically' },
				{ value: 'lump-sum', text: 'Paid in lump sum' },
				{
					value: 'waiver',
					text: 'No spousal support payable, waiving all future support',
				},
				{
					value: 'no-spousal-no-waiver',
					text: 'No spousal support currently payable, but the right to spousal support has not been waived',
				},
			]"
			:testid="'spousal-support-paid-or-waived'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mt-2"
			:condition="spousalSupport.paymentType === 'periodic'"
		>
			<radio-group
				v-model="spousalSupport.payor"
				:agreement-data="agreementData"
				input-cols="4"
				label="Which party will pay the Spousal Support?"
				partner
				reset-undefined
				select
				:testid="'spousal-support-payor'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="spousalSupport.commencementTrigger"
				class="mt-2"
				select
				input-cols="11"
				reset-undefined
				label="When will Spousal Support commence?"
				:options="[
					{
						value: 'agreement',
						text: 'On a specified day, immediately following the Agreement being signed',
					},
					{
						value: 'house',
						text: 'On a specified day, immediately following the selling of the house',
					},
					{ value: 'date', text: 'On a particular date' },
				]"
				:testid="'spousal-support-commencement'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.commencementTrigger === 'agreement'"
			>
				<radio-group
					v-model="spousalSupport.commencementDay"
					:options="dayOptions"
					input-cols="2"
					label="On which day of the month?"
					reset-undefined
					select
					:testid="'spousal-support-month'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.commencementTrigger === 'house'"
			>
				<radio-group
					v-model="spousalSupport.commencementDay"
					:options="dayOptions"
					input-cols="2"
					label="Day"
					reset-undefined
					select
					:testid="'spousal-support-commencement-day'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.commencementTrigger === 'date'"
			>
				<date-input
					v-model="spousalSupport.commencementDate"
					input-cols="4"
					reset-undefined
					label="Date"
					:testid="'spousal-support-commencement-date'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<input-group
				v-model="spousalSupport.periodicPayment"
				class="mt-2"
				reset-undefined
				label="What is the monthly amount of Spousal Support?"
				dollar
				:testid="'spousal-support-periodic-payment'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="spousalSupport.paymentFrequency"
				class="mt-2"
				input-cols="4"
				select
				label="Spousal Support will be paid in:"
				reset-undefined
				:options="[
					{ value: 'one-payment', text: 'One payment' },
					{ value: 'two-payments', text: 'Two payments' },
					{ value: 'bi-weekly-payments', text: 'Bi-weekly payments' },
				]"
				:testid="'spousal-support-payment-frequency'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.paymentFrequency === 'one-payment'"
			>
				<radio-group
					v-model="spousalSupport.firstPaymentDay"
					:options="dayOptions"
					input-cols="2"
					label="What day of each month will Spousal Support continue to be paid?"
					reset-undefined
					select
					:testid="'spousal-support-first-payment-day'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.paymentFrequency === 'two-payments'"
			>
				<radio-group
					v-model="spousalSupport.firstPaymentDay"
					class="mb-2"
					:options="dayOptions"
					input-cols="2"
					label="On what day of the month will the first Spousal Support payment continue to be paid?"
					reset-undefined
					select
					:testid="'spousal-support-first-payment-month'"
					:disabled="isViewMode"
				/>

				<radio-group
					v-model="spousalSupport.secondPaymentDay"
					:options="dayOptions"
					input-cols="2"
					label="On what day of the month will the second Spousal Support payment continue to be paid?"
					reset-undefined
					select
					:testid="'spousal-support-second-payment-month'"
					:disabled="isViewMode"
				/>
			</conditional-group>


			<radio-group
				v-model="spousalSupport.endPoint"
				:agreement-data="agreementData"
				input-cols="4"
				label="Is spousal support paid indefinitely or with a specified end point?"
				:options="['Indefinitely','specified end point']"
				reset-undefined
				:testid="'spousal-support-end-point'"
				class="mt-2"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="mt-2"
				:condition="spousalSupport.endPoint === 'specified end point'"
			>
				<conditional-check-group
					v-model="spousalSupport.endingCircumstances"
					class="mt-3"
					input-cols="10"
					label="Spousal Support will be payable until"
					reset-undefined
					select
					:options="[
						'A specific Date',
						'The payor retires',
						'The recipient retires',
						'The payor dies',
						'The recipient dies',
						'The recipient begins living in a marriage-like relationship',
						'The recipient becomes self-supporting',
					]"
					:testid="'spousal-support-payable-options-'"
					:id="'spousal-support-payable-options-'"
					:disabled="isViewMode"
				>
					<template slot="A specific Date">
						<date-input
							v-model="spousalSupport.endDate"
							label="Date"
							reset-undefined
							:testid="'spousal-support-end-date'"
							:disabled="isViewMode"
						/>
					</template>
				</conditional-check-group>
			</conditional-group>

			<yes-no-choice
				v-model="spousalSupport.reviewable"
				reset-undefined
				label="Is Spousal Support subject to review and variation?"
				class="mt-2"
				:testid="'spousal-support-reviewable'"
				:disabled="isViewMode"
			/>

			<conditional-group
				:condition="
					(!childSupport.reviewable && spousalSupport.reviewable) ||
					(spousalSupport.reviewable &&
						!agreementData.agreement.sections.includes(
							'Child Support'
						))
				"
			>
				<radio-group
					v-model="spousalSupport.reviewYear"
					reset-undefined
					class="mt-3"
					input-cols="4"
					select
					label="In what year will review commence?"
					:options="yearOptions"
					:testid="'spousal-support-review-year'"
					:disabled="isViewMode"
				/>
			</conditional-group>
		</conditional-group>

		<conditional-group
			class="mt-2"
			:condition="spousalSupport.paymentType === 'lump-sum'"
		>
			<radio-group
				v-model="spousalSupport.payor"
				:agreement-data="agreementData"
				input-cols="4"
				label="Which party will pay the Spousal Support?"
				partner
				reset-undefined
				select
				:testid="'spousal-support-payor'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="spousalSupport.lumpSumPayment"
				class="mt-2"
				reset-undefined
				label="What is the lump sum spousal payment?"
				dollar
				:testid="'spousal-support-lump-sum-payment'"
				:disabled="isViewMode"
			/>

			<!-- <conditional-group class="mt-2" :condition="hasFamilyResidence">
				<yes-no-choice
					v-model="spousalSupport.payableFromFamilyResidenceProceeds"
					reset-undefined
					label="Is the Lump Sum Spousal payable out of the payor's share of the proceeds of the Family Residence?"
					class="mt-2"
					:testid="'spousal-support-payable-from-family-residence-proceeds'"
					:disabled="isViewMode"
				/>
			</conditional-group> -->
			<radio-group
				v-model="spousalSupport.payableFromFamilyResidenceProceedsOrProperty"
				class="mb-2"
				input-cols="10"
				label="How will the lump Lump Sum Spousal Support be paid?"
				reset-undefined
				select
				:options="lumpsumSpousalSupportPaymentOptions"
				:testid="'lumpsum-spousal-support-payment-options'"
			/>
			<!-- <conditional-group
				class="my-3"
				:condition="spousalSupport.payableFromFamilyResidenceProceedsOrProperty === 'additionalProperty' && 
				additionalPropertyChoices.length>1"
			>
				<b-form-group
					id="input-additionalPropertySelection"
					input-cols="8"
					label="Select the additional property you would like to use"
					label-for="additionalPropertySelection"
				>
					<b-form-select
						id="additionalPropertySelection"
						v-model="spousalSupport.payableFromWhichProperty"
						size="sm"
						:options="additionalPropertyChoices"
						data-testid="additional-property-spousal-support"
					/>
				</b-form-group>
			</conditional-group> -->
		</conditional-group>
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="spousalSupport.additionalClausesToRecitals" :testid="'spousal-support-add-clause-to-recitals-'"
		:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="spousalSupport.additionalClauses" :testid="'spousal-support-add-clause-'"
		:disabled="isViewMode"/>
	</b-card>
</template>
