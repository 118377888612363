import get from 'lodash/get';

export default (a) =>
	get(a, 'partner1')
		? `${get(a, 'partner1.name.first', '')} ${get(
				a,
				'partner1.name.last',
				''
		  )} and ${get(a, 'partner2.name.first', '')} ${get(
				a,
				'partner2.name.last',
				''
		  )}`
		: '';
