<script>
import moment from 'moment';
import {  BIconDownload } from 'bootstrap-vue';

export default {
    props:['agreementData'],
    components:{
        BIconDownload
    },
    data(){
        return{
            partyInformation:[
                {label: 'Name:',value: this.agreementData.partner1.name.preferred},
                {label: 'Full Name:',value: `${this.agreementData.partner1.name.first || ''} ${this.agreementData.partner1.name.middle || ''} ${this.agreementData.partner1.name.last || ''}`},
                {label: 'Email:',value: this.agreementData.partner1.mail},
                {label: 'Address:',value: [this.agreementData.partner1.address.street, this.agreementData.partner1.address.city, this.agreementData.partner1.address.province]
                    .filter(Boolean)
                    .join(', ')},
                {label: 'Pronouns:',value: this.formatPronouns(this.agreementData.partner1.pronouns)},
                {label: 'Age:',value: this.calculateAge(this.agreementData.partner1.birthdate)},
                {label: 'Occupation:',value: this.agreementData.partner1.occupation},
                {label: 'Income:',value: this.formatCurrency(this.agreementData.partner1.income)}

            ],
            spouseInformation:[
                {label: 'Name:',value: this.agreementData.partner2.name.preferred},
                {label: 'Full Name:',value: `${this.agreementData.partner2.name.first || ''} ${this.agreementData.partner2.name.middle || ''} ${this.agreementData.partner2.name.last || ''}`},
                {label: 'Email:',value: this.agreementData.partner2.mail},
                {label: 'Address:',value: [this.agreementData.partner2.address.street, this.agreementData.partner2.address.city, this.agreementData.partner2.address.province]
                    .filter(Boolean)
                    .join(', ')},
                {label: 'Pronouns:',value: this.formatPronouns(this.agreementData.partner2.pronouns)},
                {label: 'Age:',value: this.calculateAge(this.agreementData.partner2.birthdate)},
                {label: 'Occupation:',value: this.agreementData.partner2.occupation},
                {label: 'Income:',value: this.formatCurrency(this.agreementData.partner2.income)}

            ],
            childrenInformation: this.agreementData.children.map((child)=>{
                return {
                    firstName: child.name.first,
                    middleName: child.name.middle,
                    lastName: child.name.last,
                    preferredName: child.name.preferred,
                    pronoun : this.formatPronouns(child.pronouns),
                    birthday: this.formatDate(child.birthdate),
                    age: this.calculateAge(child.birthdate)
                }
            })
        }
    },
    methods:{
        calculateAge(birthdate){
            if(birthdate){
                const birthMoment = moment(birthdate);
                const currentMoment = moment();
                const years = currentMoment.diff(birthMoment, 'years');
                const months = currentMoment.diff(birthMoment, 'months');

                if (years >= 1) {
                    return years + ' year(s)';
                } else {
                    return months + ' month(s)';
                }
            }
            return ''

        },
        calculateRelationshipDuration(startDate, endDate) {
            if (startDate && endDate) {
                const start = moment(startDate);
                const end = moment(endDate);
                const years = end.diff(start, 'years');
                const months = end.diff(start, 'months');

                if (years >= 1) {
                    return years + ' year(s)';
                } else {
                    return months + ' month(s)';
                }
            }
            
            return '';
        },
        formatDate(date){
            if(date){
               return moment(date).format('MMMM Do YYYY')
            }
            return ''
        },
        downloadFactSheet(){
            window.print()
        },
        formatPronouns(gender){
            if(gender === 'male'){
                return 'He/Him/His'
            }
            else if(gender === 'female'){
                return 'She/Her/Hers'
            }
            else if(gender === 'neutral'){
                return 'They/Them/Their'
            }
            else{
                return ''
            }
        },
        formatCurrency(value ) {
            if(!value && value !== 0){
                return
            }
            value = parseFloat(value).toFixed(2);
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD"
            }).format(value);
        },
        relationshipInformation(){
            if(this.agreementData.relationship.status === 'Cohabited for under two years with Children'){
                return [
                    {label: 'Relationship Status:',value: this.agreementData.relationship.status},
                ]
            }
            else{
                return [
                    {label: 'Relationship Status:',value: this.agreementData.relationship.status},
                    {label: 'Length of Relationship:',value:this.calculateRelationshipDuration(this.agreementData.relationship.cohabitationDate, this.agreementData.relationship.separationDate)},
                    {label: 'Cohabitation Date:', value: this.formatDate(this.agreementData.relationship.cohabitationDate)},
                    {label: 'Marriage Date (if married):', value:this.agreementData.relationship.status === 'Married'? this.formatDate(this.agreementData.relationship.marriageDate): ''},
                    {label: 'Separation Date:',value: this.formatDate(this.agreementData.relationship.separationDate)},
                ]
            } 
        }
    }

}
</script>

<template>
    <b-card class="separation-fact-sheet">
        <h4 slot="header" class="section-heading">Fact Sheet</h4>
        <b-button @click="downloadFactSheet" class="d-flex ml-auto mb-3 btn-primary print-hide align-items-center"><b-icon-download></b-icon-download>&nbsp; Download</b-button>
        
        <div class="d-flex justify-content-between">
            <div>
                <div class="page-heading">SEPARATION FACT SHEET</div>
                <div>({{ agreementData.partner1.name.last }} - {{ agreementData.partner2.name.last }})</div>
            </div>
                
            <img src="@/assets/Divii-Logo-Primary.svg" alt="divii logo" width="100" height="45"  />
        </div>
        <hr class="fact-sheet-hr mb-5">
        <div class="d-flex justify-content-between mb-5 flex-wrap">
            <b-table-simple bordered responsive class="col-12 col-sm-6">
                <b-thead>
                    <b-tr>
                        <th colspan="2" class="font-weight-bold fact-sheet-heading">Party Information</th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item in partyInformation" :key="item.id" class="row-bg-white">
                        <td class="text-left font-weight-bold">{{ item.label }}</td>
                        <td>
                            {{ item.value }}
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple bordered responsive class="col-12 col-sm-6">
                <b-thead>
                    <b-tr>
                        <th colspan="2" class="font-weight-bold fact-sheet-heading">Spouse Information</th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item in spouseInformation" :key="item.id" class="row-bg-white">
                        <td class="text-left font-weight-bold">{{ item.label }}</td>
                        <td>
                            {{ item.value }}
                        </td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>

        <b-table-simple bordered responsive class="col-12 mb-5">
            <b-thead>
                <b-tr>
                    <th colspan="2" class="font-weight-bold fact-sheet-heading">Relationship Information</th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="item in relationshipInformation()" :key="item.id" class="row-bg-white">
                    <td class="text-left font-weight-bold">{{ item.label }}</td>
                    <td class="col-5">
                        {{ item.value }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple bordered responsive class="col-12 mb-5" v-if="agreementData.children.length > 0">
            <b-thead>
                <b-tr>
                    <th colspan="7" class="font-weight-bold fact-sheet-heading">Children Information</th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="item in childrenInformation" :key="item.id" class="row-bg-white">
                    <td>
                        {{ item.firstName }}
                    </td>
                    <td>
                        {{ item.middleName }}
                    </td>
                    <td>
                        {{ item.lastName }}
                    </td>
                    <td>
                        {{ item.preferredName }}
                    </td>
                    <td>
                        {{ item.pronoun }}
                    </td>
                    <td>
                        {{ item.birthday }}
                    </td>
                    <td>
                        {{ item.age }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple bordered responsive class="col-12 mb-5" v-if="agreementData.agreement.sections.length > 0">
            <b-thead>
                <b-tr>
                    <th colspan="4" class="font-weight-bold fact-sheet-heading">Issues</th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr class="row-bg-white">
                    <td v-for="(item,index) in agreementData.agreement.sections" :key="index">
                        <template v-if="item === 'Property'">
                            Property Distribution
                        </template>
                        <template v-else>
                            {{ item }}
                        </template>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

    </b-card>
</template>

<style scoped>
.page-heading{
    color: #3F6772;
    font-weight: 700;
    font-size: 1.3rem;
}
.fact-sheet-heading{
    background-color: #3F6772 !important;
    color: white !important;
}

.row-bg-white{
    background-color: #ffff !important;
}

.fact-sheet-hr{
    border-top: 1px solid black !important;
}

@media print {	
	.print-hide {
		display: none !important;
	}
    .separation-fact-sheet .card-header{
        display: none !important;
    }
    .separation-fact-sheet.card{
        border: none !important;
    }
    table .fact-sheet-heading{
        background-color: #3F6772 !important;
        color: white !important;
    }

    table .row-bg-blue td{
        background-color: #678B95 !important;
    }
    table .row-bg-grey td{
        background-color: #9CB6BD !important;
    }
}
</style>