import axios from 'axios';

export default function emailApiRequest(method, url, data) {

	// Get the Firebase auth token to authenticate the request
	const base =
		// process.env.NODE_ENV !== 'production'
			// ? 'http://localhost:5001/divii-development/northamerica-northeast1/emailApi'
			// : 
			`https://northamerica-northeast1-${process.env.VUE_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/emailApi`;

		var request = {
			method,
			url: `${base}${url}`
		};

		if (method === 'POST') {
			request.contentType = 'application/json';
			request.data = data;
		}

		return axios(request).catch(function (err) {
			console.log('err',err)
			throw new Error('Request error: ' + method + ' ' + url);
		});
}
