<script>
import { getPartnerName, pureFilters } from '@/lib/agreement/filters';
import partnerNames from '@/mixins/partnerNames';
import childrenNames from '@/mixins/childrenNames';
import dayOptions from '@/lib/dayOptions';
import ConditionalGroup from '../Form/ConditionalGroup.vue';
import RadioGroup from '../Form/RadioGroup.vue';
import { getYear } from 'date-fns';
import range from 'lodash/range';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	components: { ConditionalGroup, RadioGroup },
	filters: {
		formatCurrency: pureFilters.formatCurrency,
	},
	mixins: [partnerNames, childrenNames],
	props: ['agreementData'],
	data() {
		const year = getYear(new Date());
		return { dayOptions, yearOptions: range(year, year + 5),	
			isViewMode : !hasEditPermission('section-child-support') 
		};	
	},
	computed: {
		parenting() {
			return this.agreementData.parenting;
		},
		primaryParent() {
			return getPartnerName(
				this.parenting.primaryParent,
				this.agreementData
			);
		},
		otherParent() {
			return getPartnerName(
				this.parenting.primaryParent,
				this.agreementData,
				true
			);
		},
		childSupport() {
			return this.agreementData.childSupport
			//  = this.agreementData.childSupport.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.childSupport 
			// 	: {...this.agreementData.childSupport, additionalClausesToRecitals: []};
		},
		partner1Payor() {
			return this.partner1Amount > this.partner2Amount;
		},
		payorName() {
			return this.partner1Payor ? this.partner1Name : this.partner2Name;
		},
		payeeName() {
			return this.partner1Payor ? this.partner2Name : this.partner1Name;
		},
		setOff() {
			return Math.abs(this.partner1Amount - this.partner2Amount);
		},
		partner1Amount() {
			return Number(this.childSupport.partner1Amount) || 0;
		},
		partner2Amount() {
			return Number(this.childSupport.partner2Amount) || 0;
		},
		children() {
			return this.agreementData.children;
		},

		sections() {
			return this.agreementData.agreement.sections;
		},
	},
	watch: {
		partner1Payor(val) {
			this.childSupport.setOffParty = val ? 0 : 1;
		},
		setOff(val) {
			this.childSupport.setOffAmount = Number(val).toFixed(2);
		},
	},
	mounted(){
		this.agreementData.childSupport = this.agreementData.childSupport.hasOwnProperty('additionalClausesToRecitals') 
				? this.agreementData.childSupport 
				: {...this.agreementData.childSupport, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Child Support Details</h4>

		<conditional-group :condition="!sections.includes('Parenting')">
			<radio-group
				v-model="parenting.arrangement"
				class="mb-3"
				input-cols="8"
				label="What is the Parenting Arrangement?"
				reset-undefined
				select
				:options="[
					{
						value: 'Primary',
						text: 'Primary with Parenting Time for other parent',
					},
					{
						value: 'Primary/Contact',
						text: 'Primary with Contact for other party',
					},
					{ value: 'Shared', text: 'Shared Parenting' },
				]"
				:testid="'child-support-parenting-agreement'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="
					parenting.arrangement === 'Primary' ||
					parenting.arrangement === 'Primary/Contact'
				"
			>
				<radio-group
					v-model="parenting.primaryParent"
					:agreement-data="agreementData"
					input-cols="4"
					label="Who is the primary parent?"
					partner
					reset-undefined
					select
					:testid="'child-support-primary-parent'"
					:length="'long'"
					:disabled="isViewMode"
				/>
			</conditional-group>
		</conditional-group>

		<p
			v-if="
				parenting.arrangement === 'Primary' ||
				parenting.arrangement === 'Primary/Contact'
			"
		>
			In this primary parenting situation, {{ primaryParent }} is the
			primary parent.
		</p>

		<conditional-group
			class="my-3"
			:condition="
				parenting.arrangement === 'Primary' ||
				parenting.arrangement === 'Primary/Contact'
			"
			@reset="childSupport.payment = undefined"
		>
			<input-group
				v-model="childSupport.payment"
				:label="`What amount of Child Support must ${otherParent} pay?`"
				dollar
				:testid="'child-support-payment'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<p v-if="parenting.arrangement === 'Shared'">
			Since this is a shared parenting situation, please provide the child
			support information for both parties.
		</p>

		<conditional-group
			class="my-3"
			:condition="parenting.arrangement === 'Shared'"
			@reset="
				childSupport.partner1Amount = undefined;
				childSupport.partner2Amount = undefined;
				childSupport.setOffParty = undefined;
				childSupport.setOffAmount = undefined;
			"
		>
			<input-group
				v-model="childSupport.partner1Amount"
				class="mb-3"
				:label="`${partner1Name} must pay Child Support amounting to`"
				dollar
				:testid="'child-support-p1-amounting'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="childSupport.partner2Amount"
				class="mb-3"
				:label="`${partner2Name} must pay Child Support amounting to`"
				input-cols="4"
				dollar
				:testid="'child-support-p2-amounting'"
				:disabled="isViewMode"
			/>

			<p>
				Suggested calculation:
				<br />
				<br />
				The set-off is usually calculated by subtracting the lower child
				support payment from the higher child support payment. According
				to these numerical entries, {{ payorName }} should pay to
				{{ payeeName }} {{ setOff | formatCurrency }} per month.
			</p>

			<radio-group
				v-model="childSupport.setOffParty"
				input-cols="4"
				class="mb-3"
				label="The set-off will be paid by"
				select
				partner
				:agreement-data="agreementData"
				:testid="'child-support-set-off-party'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="childSupport.setOffAmount"
				label="The set-off is"
				dollar
				:testid="'child-support-set-off-amount'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="childSupport.commencementTrigger"
			class="mt-2"
			select
			input-cols="8"
			reset-undefined
			label="On what date will Child Support commence?"
			:options="[
				{
					value: 'agreement',
					text: 'On a specified day, immediately following the Agreement being signed',
				},
				{
					value: 'house',
					text: 'On a specified day, immediately following the transfer of the Family Residence',
				},
				{ value: 'date', text: 'On a particular date' },
			]"
			:testid="'child-support-commencement-date-specification'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mt-2"
			:condition="childSupport.commencementTrigger === 'agreement'"
		>
			<radio-group
				v-model="childSupport.commencementDay"
				class="mb-3 mt-3"
				:options="dayOptions"
				input-cols="2"
				label="On which day of the month?"
				reset-undefined
				select
				:testid="'child-support-commencement-month'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mt-2"
			:condition="childSupport.commencementTrigger === 'house'"
		>
			<radio-group
				v-model="childSupport.commencementDay"
				:options="dayOptions"
				input-cols="2"
				label="Day"
				reset-undefined
				select
				:testid="'child-support-commencement-day'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mt-2"
			:condition="childSupport.commencementTrigger === 'date'"
		>
			<date-input
				v-model="childSupport.commencementDate"
				input-cols="4"
				reset-undefined
				label="Date"
				:testid="'child-support-commencement-date'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="childSupport.paymentOptions"
			select
			class="mb-3 mt-4"
			input-cols="4"
			label="Child support will be paid in:"
			:options="['One payment', 'Two payments', 'Bi-weekly payments']"
			:testid="'child-support-payment-options'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mt-3"
			:condition="childSupport.paymentOptions === 'One payment'"
			@reset="childSupport.firstPaymentDay = undefined"
		>
			<radio-group
				v-model="childSupport.firstPaymentDay"
				select
				class="mt-3 mb-2"
				input-cols="3"
				label="What day of each month will Child Support continue to be paid?"
				:options="dayOptions"
				:testid="'child-support-day-options'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mt-3"
			:condition="childSupport.paymentOptions === 'Two payments'"
			@reset="
				childSupport.firstPaymentDay = undefined;
				childSupport.secondPaymentDay = undefined;
			"
		>
			<radio-group
				v-model="childSupport.firstPaymentDay"
				select
				class="mb-3"
				input-cols="3"
				label="On what day of the month will the first Child Support payment continue to be paid?"
				:options="dayOptions"
				:testid="'child-support-pay-options-first-child'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="childSupport.secondPaymentDay"
				select
				input-cols="3"
				label="On what day of the month will the second Child Support payment continue to be paid?"
				:options="dayOptions"
				:testid="'child-support-pay-options-second-child'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="childSupport.reviewable"
			reset-undefined
			label="Is Child Support subject to review and variation?"
			class="mt-4"
			:testid="'child-support-reviewable'"
			:disabled="isViewMode"
		/>

		<conditional-group :condition="childSupport.reviewable">
			<radio-group
				v-model="childSupport.yearOfReview"
				class="mt-3"
				input-cols="4"
				select
				label="In what year will review commence?"
				:options="yearOptions"
				:testid="'child-support-review-year'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<p
			v-if="parenting.arrangement === 'Shared' && children.length > 1"
			class="my-3"
		>
			Given that you've selected a shared parenting arrangement with
			multiple children, for the purposes of tax:
		</p>

		<conditional-group
			class="my-3"
			:condition="
				parenting.arrangement === 'Shared' && children.length > 1
			"
		>
			<radio-group
				v-model="childSupport.partner1TaxClaim"
				input-cols="4"
				class="mb-3"
				:label="`Which child will ${partner1Name} claim as a dependant`"
				select
				:options="childrenNames"
				:testid="'child-support-p1-claim-dependant'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="childSupport.partner2TaxClaim"
				input-cols="4"
				class="mb-3"
				:label="`Which child will ${partner2Name} claim as a dependant`"
				select
				:options="childrenNames"
				:testid="'child-support-p2-claim-dependant'"
				:length="'long'"
				:disabled="isViewMode"
			/>
		</conditional-group>
		<additional-clauses class="mt-2" :newLabel="'Add Clause to Recitals'" :title="'Additional Clauses into Recitals'" v-model="childSupport.additionalClausesToRecitals" :testid="'child-support-add-clause-to-recitals-'"
		:disabled="isViewMode"/>
		<additional-clauses class="mt-2" :newLabel="'Add Clause to Section'" :title="'Additional Clauses into Sections'" v-model="childSupport.additionalClauses" :testid="'child-support-add-clause-'"
		:disabled="isViewMode"/>
	</b-card>
</template>
