import { mapState } from 'vuex';

export default {
	computed: {
		...mapState(['user', 'agreement', 'permissions']),
	},
	data() {
		return {
			agreementData:this.agreement,
		}
	},
	methods: {
		hasPermission(role) {
			// if(this.agreement.id){
			// 	console.log('inside')
			// 	// return this.agreement.id && this.permissions[this.user.uid][role];
				
			// }else if(this.agreementData.id){
			// 	// console.log('outside')
			// 	// this.assignAgreementData()	
			// 	return this.agreementData.id && this.permissions[this.user.uid][role];
			// }
			if(this.user && this.user.uid && this.permissions[this.user.uid]){
				return this.permissions[this.user.uid][role];
			}
		},
		assignAgreementData(){
			this.agreementData=this.agreement
		}
	},
};
