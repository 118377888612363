var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"section-heading",attrs:{"slot":"header"},slot:"header"},[_vm._v("Parenting Agreements")]),_c('yes-no-choice',{staticClass:"mb-3",attrs:{"label":("Are the parents intending to exercise parenting time in a nesting arrangement where " + _vm.childNameOrChildren + " will remain in the Family Residence and the parents will come and go according to the Parenting Time Schedule?"),"reset-undefined":"","testid":'parenting-agreement-child-communication',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.nestingArrangement),callback:function ($$v) {_vm.$set(_vm.parenting, "nestingArrangement", $$v)},expression:"parenting.nestingArrangement"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":_vm.parenting.nestingArrangement}},[_c('radio-group',{staticClass:"mt-3",attrs:{"select":"","reset-undefined":"","input-cols":"8","label":"Where will the parties reside when it is not their Parenting Time?","options":[
				'Each will maintain their own separate residence',
				'The parties will share a second residence' ],"testid":'parenting-agreement-reside-not-parenting-time',"length":'long',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.nestingResidence),callback:function ($$v) {_vm.$set(_vm.parenting, "nestingResidence", $$v)},expression:"parenting.nestingResidence"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":_vm.parenting.nestingResidence ===
				'The parties will share a second residence'}},[_c('radio-group',{staticClass:"mb-3",attrs:{"reset-undefined":"","input-cols":"8","label":"How will the parties share the cost of the Shared Second Residence?","select":"","options":[
					{
						text: 'Equally between them',
						value: 'equally between them',
					}, // value fix
					{
						text: 'As agreed between them',
						value: 'as agreed between them',
					},
					{
						text: 'In proportion to their incomes as calculated under the Federal Child Support Guidelines',
						value: 'in proportion to their incomes as calculated under the Federal Child Support Guidelines',
					} ],"testid":'parenting-agreement-shared-second-residence-cost-share',"length":'long',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.nestingSecondResidencePlan),callback:function ($$v) {_vm.$set(_vm.parenting, "nestingSecondResidencePlan", $$v)},expression:"parenting.nestingSecondResidencePlan"}})],1),_c('check-group',{staticClass:"mt-3",attrs:{"reset-undefined":"","label":"What expectations of shared household upkeep would you like to include?","other":"","options":[
				{
					text: 'Leaving the kitchen clean, organized, and with clean dishes',
					value: 'leaving the kitchen clean, organized, and with clean dishes',
				}, // value fix
				{
					text: ("Removing food from the refrigerator that is not for " + _vm.childNameOrChildren),
					value: ("removing food from the refrigerator that is not for " + _vm.childNameOrChildren),
				},
				{
					text: 'Cleaning the bathroom for the other parent’s use',
					value: 'cleaning the bathroom for the other parent’s use',
				},
				{
					text: 'Removing the sheets from the bed in the master bedroom',
					value: 'removing the sheets from the bed in the master bedroom',
				} ],"testid":'parenting-agreement-nesting-second-residence-up-keep',"id":'parenting-agreement-nesting-second-residence-up-keep',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.nestingSecondResidenceUpkeep),callback:function ($$v) {_vm.$set(_vm.parenting, "nestingSecondResidenceUpkeep", $$v)},expression:"parenting.nestingSecondResidenceUpkeep"}})],1),_c('yes-no-choice',{staticClass:"mb-3",attrs:{"label":("Will the parties facilitate communication between " + _vm.childNameOrChildren + " and the other party during parenting time?"),"reset-undefined":"","testid":'parenting-agreement-facilitate-communication',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.facilitatedCommunication),callback:function ($$v) {_vm.$set(_vm.parenting, "facilitatedCommunication", $$v)},expression:"parenting.facilitatedCommunication"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":_vm.parenting.facilitatedCommunication}},[_c('check-group',{staticClass:"mb-3",attrs:{"reset-undefined":"","options":[
				{ text: 'Telephone', value: 'telephone calls' }, // value fix
				{ text: 'Text message', value: 'text messages' },
				{ text: 'Video call', value: 'video calls' } ],"label":"By what method of communication?","other":"","testid":'parenting-agreement-method-of-communication',"id":'parenting-agreement-method-of-communication',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.facilitatedCommunicationMethods),callback:function ($$v) {_vm.$set(_vm.parenting, "facilitatedCommunicationMethods", $$v)},expression:"parenting.facilitatedCommunicationMethods"}}),_c('radio-group',{attrs:{"reset-undefined":"","options":[
				{
					text: ("At times determined by " + _vm.childNameOrChildren), // value fixs very hard
					value: ("at times determined by " + _vm.childNameOrChildren),
				},
				{
					text: 'When the other parent makes contact',
					value: 'when the other parent makes contact',
				},
				{
					text: 'As agreed between the parties',
					value: 'as agreed between the parties',
				} ],"input-cols":"6","select":"","label":"When will this communication occur?","other":"","testid":'parenting-agreement-facilitated-communication-when',"length":'long',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.facilitatedCommunicationWhen),callback:function ($$v) {_vm.$set(_vm.parenting, "facilitatedCommunicationWhen", $$v)},expression:"parenting.facilitatedCommunicationWhen"}})],1),_c('additional-clauses',{staticClass:"mt-2",attrs:{"title":'Additional Clauses into Recitals',"newLabel":'Add Clause to Recitals',"testid":'parenting-agreement-add-clause-to-recitals-',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.additionalClausesParentingAgreementsToRecitals),callback:function ($$v) {_vm.$set(_vm.parenting, "additionalClausesParentingAgreementsToRecitals", $$v)},expression:"parenting.additionalClausesParentingAgreementsToRecitals"}}),_c('additional-clauses',{staticClass:"mt-2",attrs:{"title":'Additional Clauses into Sections',"newLabel":'Add Clause to Section',"testid":'parenting-agreement-add-clause-',"disabled":_vm.isViewMode},model:{value:(_vm.parenting.additionalClausesParentingAgreements),callback:function ($$v) {_vm.$set(_vm.parenting, "additionalClausesParentingAgreements", $$v)},expression:"parenting.additionalClausesParentingAgreements"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }