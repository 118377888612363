<script>
import partnerNames from '@/mixins/partnerNames';
import childNameOrChildren from '@/mixins/childNameOrChildren';
import childrenNames from '@/mixins/childrenNames';
import ConditionalGroup from '../Form/ConditionalGroup.vue';
import pureFilters from '@/lib/agreement/filters';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	components: { ConditionalGroup },
	mixins: [childNameOrChildren, partnerNames, childrenNames],
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-support-additionals') 
		};
	},
	computed: {
		supportAdditionals() {
			return this.agreementData.supportAdditionals;
			// return this.agreementData.supportAdditionals =  this.agreementData.supportAdditionals.hasOwnProperty('additionalClausesMedicalCoverageToRecitals') 
			// 	? this.agreementData.supportAdditionals 
			// 	: {...this.agreementData.supportAdditionals, additionalClausesMedicalCoverageToRecitals: []};;
		},
		children() {
			return this.agreementData.children;
		},
		notSelfSupportingChildren(){
			return this.agreementData.children.filter((child)=> !child.selfSupporting)
		}
	},
	methods: {
		beneficiaryOptions(partnerName){
			let beneficiaryValue = [];
			if(this.notSelfSupportingChildren.length > 0 && (this.agreementData.agreement.sections.includes('Child Support') || 
				this.agreementData.agreement.sections.includes('Parenting'))){
				beneficiaryValue = [
						{ value: 'partner', text: partnerName },
						{ value: 'children', text: this.notSelfSupportingChildren.length === 1 ?
							pureFilters.preferredName(this.notSelfSupportingChildren[0]) : this.childNameOrChildren },
						'Both',
					]
			}else{
				beneficiaryValue = [
						{ value: 'partner', text: partnerName },
					]
			}
			return beneficiaryValue
		}
	},
	mounted(){
		this.agreementData.supportAdditionals =  this.agreementData.supportAdditionals.hasOwnProperty('additionalClausesMedicalCoverageToRecitals') 
			? this.agreementData.supportAdditionals 
			: {...this.agreementData.supportAdditionals, additionalClausesMedicalCoverageToRecitals: []};;
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Medical Coverage</h4>

		<yes-no-choice
			v-model="supportAdditionals.medicalCoveragePartner1"
			reset-undefined
			:label="`Will ${partner1Name} cover any dependants on a medical plan?`"
			class="mb-2"
			:testid="'medical-p1-dependants'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="supportAdditionals.medicalCoveragePartner1"
		>
			<radio-group
				v-model="supportAdditionals.medicalCoveragePartner1Plan"
				class="mb-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner1Name} be maintaining a current plan or obtaining a new plan?`"
				reset-undefined
				select
				:options="[
					{ text: 'Maintain', value: 'maintain' },
					{ text: 'Obtain', value: 'obtain' },
				]"
				:testid="'medical-p1-maintain-or-obtain'"
				:disabled="isViewMode"
			/>

			<!-- <conditional-group class="my-3"> -->
				<radio-group
					v-model="
						supportAdditionals.medicalCoveragePartner1Beneficiary
					"
					:agreement-data="agreementData"
					input-cols="4"
					label="Who is the beneficiary of this medical coverage?"
					reset-undefined
					select
					:options="beneficiaryOptions(partner2Name)"
					:testid="'medical-p1-beneficiary'"
					:length="'long'"
					:disabled="isViewMode"
				/>
			<!-- </conditional-group> -->
		</conditional-group>

		<yes-no-choice
			v-model="supportAdditionals.medicalCoveragePartner2"
			reset-undefined
			:label="`Will ${partner2Name} cover any dependants on a medical plan?`"
			class="mb-2"
			:testid="'medical-p2-dependants'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="supportAdditionals.medicalCoveragePartner2"
		>
			<radio-group
				v-model="supportAdditionals.medicalCoveragePartner2Plan"
				class="mb-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner2Name} be maintaining a current plan or obtaining a new plan?`"
				reset-undefined
				select
				:options="[
					{ text: 'Maintain', value: 'maintain' },
					{ text: 'Obtain', value: 'obtain' },
				]"
				:testid="'medical-p2-maintain-or-obtain'"
				:disabled="isViewMode"
			/>

			<!-- <conditional-group class="my-3"> -->
				<radio-group
					v-model="
						supportAdditionals.medicalCoveragePartner2Beneficiary
					"
					:agreement-data="agreementData"
					input-cols="4"
					label="Who is the beneficiary of this medical coverage?"
					reset-undefined
					select
					:options="beneficiaryOptions(partner1Name)"
					:testid="'medical-p2-beneficiary'"
					:length="'long'"
					:disabled="isViewMode"
				/>
			<!-- </conditional-group> -->

		</conditional-group>
		<additional-clauses
			:title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-3"
			v-model="supportAdditionals.additionalClausesMedicalCoverageToRecitals" :testid="'medical-add-clause-to-recitals-'"
			:disabled="isViewMode"
		/>
		<additional-clauses
		:title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2"
			v-model="supportAdditionals.additionalClausesMedicalCoverage" :testid="'medical-add-clause-'"
			:disabled="isViewMode"
		/>
	</b-card>
</template>
