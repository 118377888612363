<script>
import partnerNames from '@/mixins/partnerNames';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerNames],
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-support-additionals') 
		};
	},
	computed: {
		supportAdditionals() {
			return this.agreementData.supportAdditionals;
			// return this.agreementData.supportAdditionals =  this.agreementData.supportAdditionals.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.supportAdditionals 
			// 	: {...this.agreementData.supportAdditionals, additionalClausesToRecitals: []};
		},

		// partner1SecurityOptions() {
		// 	return this.agreementData.supportAdditionals
		// 		.securityForSupportPartner2Securing === 'both' ||
		// 		this.agreementData.supportAdditionals
		// 			.securityForSupportPartner2Securing === 'spousal-support'
		// 		? [{ text: 'Child Support', value: 'child-support' }]
		// 		: [
		// 				{ text: 'Child Support', value: 'child-support' },
		// 				{ text: 'Spousal Support', value: 'spousal-support' },
		// 				{
		// 					text: 'Both',
		// 					value: 'both',
		// 				},
		// 		  ];
		// },
		// partner2SecurityOptions() {
		// 	return this.agreementData.supportAdditionals
		// 		.securityForSupportPartner1Securing === 'both' ||
		// 		this.agreementData.supportAdditionals
		// 			.securityForSupportPartner1Securing === 'spousal-support'
		// 		? [{ text: 'Child Support', value: 'child-support' }]
		// 		: [
		// 				{ text: 'Child Support', value: 'child-support' },
		// 				{ text: 'Spousal Support', value: 'spousal-support' },
		// 				{
		// 					text: 'Both',
		// 					value: 'both',
		// 				},
		// 		  ];
		// },
	},
	methods: {
		lifeInsuranceOptions(){
			let options = [{ value: 'partner1', text: this.partner1Name },
						{ value: 'partner2', text: this.partner2Name }, { value: false, text: 'Neither' }];
			if(this.agreementData.agreement.sections.includes('Child Support')){
					// options.push('Both')
					options.splice(2, 0, 'Both')
			}
			this.partnerSecuringValues();
			return options
		},
		securityOptions(partner) {
			return partner==='partner1'? this.partnerSecurityOptions(this.agreementData.supportAdditionals
				.securityForSupportPartner2Securing) : this.partnerSecurityOptions(this.agreementData.supportAdditionals
				.securityForSupportPartner1Securing)
		},
		partnerSecurityOptions(securityForSupportPartnerSecuring){
			let options= [];
            if(this.agreementData.agreement.sections.includes('Child Support'))
                options.push({ value: 'child-support', text: 'Child Support' })
            if(this.agreementData.agreement.sections.includes('Spousal Support'))
                options.push({ text: 'Spousal Support', value: 'spousal-support' })
            if(this.agreementData.agreement.sections.includes('Child Support') && 
            this.agreementData.agreement.sections.includes('Spousal Support'))
            {
                options = securityForSupportPartnerSecuring === 'both' ||
				securityForSupportPartnerSecuring === 'spousal-support'
				? [{ text: 'Child Support', value: 'child-support' }]
				: [
						{ text: 'Child Support', value: 'child-support' },
						{ text: 'Spousal Support', value: 'spousal-support' },
						{
							text: 'Both',
							value: 'both',
						},
				  ];
            }
			return options
		},
		partnerSecuringValues(){
			if(!(this.agreementData.agreement.sections.includes('Child Support') && this.agreementData.agreement.sections.includes('Spousal Support'))){
				if(this.agreementData.supportAdditionals.securityForSupportLifeInsurance=='partner1' ||
				this.agreementData.supportAdditionals.securityForSupportLifeInsurance=='Both')
				{
					this.agreementData.supportAdditionals.securityForSupportPartner1Securing = this.agreementData.agreement.sections.includes('Child Support') ? 'child-support':'spousal-support'
				}if(this.agreementData.supportAdditionals.securityForSupportLifeInsurance=='partner2' ||
				this.agreementData.supportAdditionals.securityForSupportLifeInsurance=='Both')
				{
					this.agreementData.supportAdditionals.securityForSupportPartner2Securing = this.agreementData.agreement.sections.includes('Child Support') ? 'child-support':'spousal-support'
				}
			}else{
				if(this.agreementData.supportAdditionals.securityForSupportPartner1Securing=='spousal-support' &&
				   this.agreementData.supportAdditionals.securityForSupportPartner2Securing=='spousal-support'){
					this.agreementData.supportAdditionals.securityForSupportPartner1Securing=''
					this.agreementData.supportAdditionals.securityForSupportPartner2Securing=''
				   }
			}
		}
	},
	mounted(){
		this.agreementData.supportAdditionals =  this.agreementData.supportAdditionals.hasOwnProperty('additionalClausesToRecitals') 
			? this.agreementData.supportAdditionals 
			: {...this.agreementData.supportAdditionals, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Security for Support</h4>

			<radio-group
					v-model="supportAdditionals.securityForSupportLifeInsurance"
					class="my-3"
					:agreement-data="agreementData"
					input-cols="4"
					:label="`Who will be providing the Life Insurance?`"
					reset-undefined
					select
					:options="lifeInsuranceOptions()"
					:testid="'support-security-life-insurance'"
					:length="'long'"
					:disabled="isViewMode"
				/>
		<conditional-group
			class="my-3"
			:condition="supportAdditionals.securityForSupportLifeInsurance == 'partner1' || 
			(supportAdditionals.securityForSupportLifeInsurance == 'Both' && agreementData.agreement.sections.includes('Child Support'))"
		>
			<radio-group
				v-model="supportAdditionals.securityForSupportPartner1Plan"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner1Name} be maintaining their current Life Insurance policy or obtaining new Life Insurance?`"
				reset-undefined
				select
				:options="[
					{ value: 'maintain', text: 'Maintaining' },
					{ value: 'obtain', text: 'Obtaining' },
				]"
				:testid="'support-security-p1-maintain-or-obtain-insurance'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="supportAdditionals.securityForSupportPartner1Amount"
				class="my-3"
				reset-undefined
				:label="`What amount of Total Life Insurance is  ${partner1Name} responsible for maintaining?`"
				dollar
				:testid="'support-security-p1-amount'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-if="agreementData.agreement.sections.includes('Child Support') && agreementData.agreement.sections.includes('Spousal Support')"
				v-model="supportAdditionals.securityForSupportPartner1Securing"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`What is ${partner1Name}'s Life Insurance policy securing?`"
				select
				:options="securityOptions('partner1')"
				:testid="'support-security-p1-policy-securing'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="my-3"
			:condition="supportAdditionals.securityForSupportLifeInsurance == 'partner2' || 
			(supportAdditionals.securityForSupportLifeInsurance == 'Both' && agreementData.agreement.sections.includes('Child Support'))"
		>
			<radio-group
				v-model="supportAdditionals.securityForSupportPartner2Plan"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner2Name} be maintaining their current Life Insurance policy or obtaining new Life Insurance?`"
				reset-undefined
				select
				:options="[
					{ value: 'maintain', text: 'Maintaining' },
					{ value: 'obtain', text: 'Obtaining' },
				]"
				:testid="'support-security-p2-maintain-or-obtain-insurance'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="supportAdditionals.securityForSupportPartner2Amount"
				class="my-3"
				reset-undefined
				:label="`What amount of Total Life Insurance is  ${partner2Name} responsible for maintaining?`"
				dollar
				:testid="'support-security-p2-amount'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-if="agreementData.agreement.sections.includes('Child Support') && agreementData.agreement.sections.includes('Spousal Support')"
				v-model="supportAdditionals.securityForSupportPartner2Securing"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`What is ${partner2Name}'s Life Insurance policy securing?`"
				select
				:options="securityOptions('partner2')"
				:testid="'support-security-p2-policy-securing'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<!-- <yes-no-choice
			v-model="supportAdditionals.securitySupportPartner1"
			reset-undefined
			:label="`Will ${partner1Name} be supplying Security for Support?`"
			class="mb-3"
			:testid="'support-security-p1-supply'"
		/>

		<conditional-group
			class="my-3"
			:condition="supportAdditionals.securitySupportPartner1"
		>
			<radio-group
				v-model="supportAdditionals.securityForSupportPartner1Plan"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner1Name} be maintaining their current Life Insurance policy or obtaining new Life Insurance?`"
				reset-undefined
				select
				:options="[
					{ value: 'maintain', text: 'Maintaining' },
					{ value: 'obtain', text: 'Obtaining' },
				]"
				:testid="'support-security-p1-maintain-or-obtain-insurance'"
			/>

			<input-group
				v-model="supportAdditionals.securityForSupportPartner1Amount"
				class="my-3"
				reset-undefined
				:label="`What amount of Total Life Insurance is  ${partner1Name} responsible for maintaining?`"
				dollar
				:testid="'support-security-p1-amount'"
			/>

			<radio-group
				v-model="supportAdditionals.securityForSupportPartner1Securing"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="What is the Life Insurance policy securing?"
				select
				:options="partnerSecurityOptions()"
				:testid="'support-security-p1-policy-securing'"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="supportAdditionals.securitySupportPartner2"
			reset-undefined
			:label="`Will ${partner2Name} be supplying Security for Support?`"
			class="my-2"
			:testid="'support-security-p2-supplying-support'"
		/>

		<conditional-group
			class="my-3"
			:condition="supportAdditionals.securitySupportPartner2"
		>
			<radio-group
				v-model="supportAdditionals.securityForSupportPartner2Plan"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				:label="`Will ${partner2Name} be maintaining their current Life Insurance policy or obtaining new Life Insurance?`"
				reset-undefined
				select
				:options="[
					{ value: 'maintain', text: 'Maintaining' },
					{ value: 'obtain', text: 'Obtaining' },
				]"
				:testid="'support-security-p2-maintain-or-obtain-insurance'"
			/>

			<input-group
				v-model="supportAdditionals.securityForSupportPartner2Amount"
				class="my-3"
				reset-undefined
				:label="`What amount of Total Life Insurance is  ${partner2Name} responsible for maintaining?`"
				dollar
				:testid="'support-security-p2-amount'"
			/>

			<radio-group
				v-model="supportAdditionals.securityForSupportPartner2Securing"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="What is the Life Insurance policy securing?"
				select
				:options="partnerSecurityOptions()"
				:testid="'support-security-p2-policy-securing'"
			/>
		</conditional-group> -->
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="supportAdditionals.additionalClausesToRecitals" :testid="'security-for-support-to-recitals'" :disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="supportAdditionals.additionalClauses" :testid="'security-for-support'" :disabled="isViewMode"/>
	</b-card>
</template>
