<script>
import { mapState } from 'vuex';

import equalizationCalculations from '@/mixins/equalizationCalculations';
import formatMoney from '@/mixins/formatMoney';

import Loader from '../Loader.vue';

export default {
	name: 'SummaryView',
	components: { Loader },
	mixins: [equalizationCalculations, formatMoney],
	computed: {
		...mapState(['agreement']),
		ready() {
			return (
				this.agreement.agreement && this.agreement.agreement.sections
			);
		},
		newAgreement() {
			return (
				this.agreement.agreement &&
				this.agreement.agreement.sections.length === 0
			);
		},
	},
	methods: {
		sectionEnabled(section) {
			return (
				this.agreement.agreement &&
				this.agreement.agreement.sections &&
				this.agreement.agreement.sections.includes(section)
			);
		},
		propertyRecitals(section) {
			return (
				this.agreement.agreement &&
				this.agreement.agreement.propertyRecitals &&
				this.agreement.agreement.propertyRecitals.includes(section)
			);
		},
	},
};
</script>

<style>
.Complete {
	background-color: #baf185 !important;
}
.AwaitingSignOff {
	background-color: #5b9ebb !important;
}
.InProgress {
	background-color: #7ed6d6 !important;
}
.NotStarted {
	background-color: #eff8e2 !important;
}
</style>

<template>
	<div>
		<div v-if="ready">
			<div v-if="newAgreement">
				<b-card
					bg-variant="primary"
					header="Let's get Started"
					class="text-center mt-5 NotStarted"
				>
					<b-card-text>
						Get started with this new agreement and a summary will
						automatically populate
					</b-card-text>
				</b-card>
			</div>
			<div class="mt-3">
				<b-card-group deck>
					<b-card
						v-if="sectionEnabled('Parenting')"
						bg-variant="primary"
						text-variant="white"
						header="Parenting"
						class="text-center InProgress"
					>
						<b-card-text>
							{{ agreement.guardianship.choice }} guardianship
							{{ agreement.parenting.arrangement }} parenting
						</b-card-text>
					</b-card>

					<!-- <b-card
						v-if="sectionEnabled('Child Support')"
						bg-variant="info"
						text-variant="white"
						header="Child Support"
						class="text-center"
					>
						<b-card-text>
							Spouse 1 will pay $1000/mo and parties will split
							special expenses 50/50
						</b-card-text>
					</b-card> -->

					<b-card
						v-if="sectionEnabled('Spousal Support')"
						bg-variant="info"
						text-variant="white"
						header="Spousal Support"
						class="text-center InProgress"
					>
						<b-card-text>
							{{ agreement.spousalSupport.paymentType }} spousal
							support
						</b-card-text>
					</b-card>
					<!-- <b-card
					bg-variant="success"
					text-variant="white"
					header="Life Insurance/Medical"
					class="text-center"
				>
					<b-card-text
						>Spouse 1 will maintain current policy life insurance
						for children of $250,000 and medical coverage for
						children</b-card-text
					>
				</b-card> -->
				</b-card-group>
			</div>
			<div class="mt-3">
				<b-card-group v-if="sectionEnabled('Property')" deck>
					<b-card
						bg-variant="primary"
						text-variant="white"
						header="Equalization Payment"
						:class="`text-center ${
							overallEqualization === 0
								? 'NotStarted'
								: 'InProgress'
						}`"
					>
						<b-card-text>
							${{ formatMoney(overallEqualization) }}
						</b-card-text>
					</b-card>

					<b-card
						v-if="propertyRecitals('RRSPs')"
						bg-variant="secondary"
						text-variant="white"
						header="RRSP"
						:class="`text-center ${
							registeredAssetSummary.value === 0
								? 'NotStarted'
								: 'InProgress'
						}`"
					>
						<b-card-text>
							${{ formatMoney(registeredAssetSummary.value) }}
						</b-card-text>
					</b-card>

					<!-- <b-card
						v-if="propertyRecitals('Pensions')"
						bg-variant="success"
						text-variant="white"
						header="Pension"
						class="text-center"
					>
						<b-card-text>Split</b-card-text>
					</b-card> -->
					<!-- <b-card
						v-if="propertyRecitals('Canada Pension Plan')"
						bg-variant="info"
						text-variant="white"
						header="CPP"
						class="text-center"
					>
						<b-card-text>each keep own cpp credits</b-card-text>
					</b-card> -->
				</b-card-group>
			</div>
		</div>

		<loader v-else />
	</div>
</template>
