<script>
import partnerNames from '@/mixins/partnerNames';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerNames],
	props: ['agreementData'],
	data() {
		return { 	
			isViewMode : !hasEditPermission('section-child-support') 
		};	
	},
	computed: {
		childSupportExpenses() {
			return this.agreementData.childSupportExpenses;
			// return this.agreementData.childSupportExpenses =  this.agreementData.childSupportExpenses.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.childSupportExpenses 
			// 	: {...this.agreementData.childSupportExpenses, additionalClausesToRecitals: []};
		},
	},
	mounted(){
		this.agreementData.childSupportExpenses =  this.agreementData.childSupportExpenses.hasOwnProperty('additionalClausesToRecitals') 
			? this.agreementData.childSupportExpenses 
			: {...this.agreementData.childSupportExpenses, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Special or Extraordinary Expenses</h4>

		<conditional-check-group
			v-model="childSupportExpenses.seeOptions"
			class="mb-3"
			label="What types of special or extraordinary expenses will the parties share?"
			:options="[
				'Child Care',
				'Health',
				'Extracurriculars',
				'Educational',
			]"
			:testid="'special-expense-type-'"
			:id="'special-expense-type-'"
			:disabled="isViewMode"
		>
			<template slot="Child Care">
				<check-group
					v-model="childSupportExpenses.childCareOptions"
					:options="[
						{ value: 'day care', text: 'Day care' }, // value fix harder
						{ value: 'camps', text: 'Camps' },
					]"
					label="Will the parties use any child care during holidays?"
					other
					@reset="childSupportExpenses.childCareOptions = []"
					:testid="'special-expense-child-care-options'"
					:id="'special-expense-child-care-options'"
					:disabled="isViewMode"
				/>
			</template>

			<template slot="Health">
				<yes-no-choice
					v-model="childSupportExpenses.seeHealthShared"
					reset-undefined
					label="Would you like to set a threshold amount after which health care expenses will be shared?"
					:testid="'special-expense-set-threshold-amount-after-health-care-expenses'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="mt-2"
					:condition="childSupportExpenses.seeHealthShared"
				>
					<input-group
						v-model="childSupportExpenses.seeHealth"
						label="What is the threshold amount?"
						reset-undefined
						dollar
						:testid="'special-expense-threshold-amount'"
						:disabled="isViewMode"
					/>
				</conditional-group>
			</template>

			<template slot="Extracurriculars">
				<yes-no-choice
					v-model="childSupportExpenses.seeExtracurricularsShared"
					reset-undefined
					label="Would you like to set a threshold amount after which extracurricular expenses will be shared?" 
					:testid="'special-expense-set-threshold-amunt-after-extracurricualr-expenses'"
					:disabled="isViewMode"/>

				<conditional-group
					class="mt-2"
					:condition="childSupportExpenses.seeExtracurricularsShared"
				>
					<input-group
						v-model="childSupportExpenses.seeExtracurriculars"
						label="What is the threshold amount?"
						reset-undefined
						dollar
						:testid="'special-expense-threshold-amount-extracurriculars'"
						:disabled="isViewMode"
					/> </conditional-group
			></template>
		</conditional-check-group>

		<input-group
			v-model="childSupportExpenses.partner1Proportion"
			:label="`${partner1Name}'s Proportion of special or extraordinary expenses`"
			class="mb-3"
			input-cols="2"
			percentage
			reset-undefined
			mobileWidth="6"
			:testid="'special-expense-p1-proportion'"
			:disabled="isViewMode"
		/>

		<input-group
			v-model="childSupportExpenses.partner2Proportion"
			:label="`${partner2Name}'s Proportion of special or extraordinary expenses`"
			class="mb-3"
			input-cols="2"
			mobileWidth="6"
			percentage
			reset-undefined
			:testid="'special-expense-p2-proportion'"
			:disabled="isViewMode"
		/>

		<yes-no-choice
			v-model="childSupportExpenses.seeAccounting"
			label="Will the parties conduct accounting of special or extraordinary expenses each year to ensure the correct amount has been paid by the parties?"
			:testid="'special-expense-conduct-accounting'"
			:disabled="isViewMode"
		/>
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="childSupportExpenses.additionalClausesToRecitals" :testid="'specialorextraordinaryexpences-add-clause-to-recitals'"
		:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="childSupportExpenses.additionalClauses" :testid="'specialorextraordinaryexpences-add-clause-to'"
		:disabled="isViewMode"/>
	</b-card>
</template>
