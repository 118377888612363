<template>
    <b-modal :title="modalContent.title" ref="subscribe-modal" :size="modalContent.size" centered @hidden="hideModal" hide-footer>
        <div class="d-flex flex-column">
            <p class="text-center mb-4 blockquote">{{ modalContent.description }}</p>
            <router-link :to="{ name: 'subscription' }" tag="b-button" class="btn-primary btn-lg">
                {{modalContent.button}}
            </router-link>
        </div> 
    </b-modal>
</template>

<script>
    export default {
        props: {
            showSubscriptionModal: {
                type: Boolean,
                default: false,
            },
            mode:{
                type:String
            }
        },
        methods: {
            hideModal() {
                this.$emit('close');
            },
        },
        watch:{
            showSubscriptionModal(value){
                if(value){
                    this.$refs['subscribe-modal'].show() 
                }
                else{
                    this.$refs['subscribe-modal'].hide() 
                }
            }
        },
        computed: {
            modalContent(){
                if(this.mode ==='trialExpired'){
                    return{
                        size:'md',
                        title:'Your trial has Expired',
                        description:" Upgrade to one of our paid plans to continue.",
                        button: 'Subscribe'
                    }
                }
                else{
                    return{
                        size:'lg',
                        title: "Move Forward with Divii and Create Your Full Separation Agreement",
                        description:"Get unlimited access to our education, calculations, and automated drafting.  Your completed Separation Agreement is only a few steps away.",
                        button:'Continue'
                    }

                }
            }
        }
    };
</script>