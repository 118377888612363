<script>
import {hasEditPermission,currentRoleLevelName, trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed: {
		agreement() {
			return this.agreementData.agreement;
		},
		isNegotiation() {
			const negotiation = this.agreement.negotiation;

			return (
				negotiation === 'Negotiation with the assistance of counsel' ||
				negotiation === 'Negotiation without the assistance of counsel'
			);
		},
		dateLabel() {
			let label = this.agreement.negotiation;

			if (label === 'CollaborativeProcess') {
				label = 'Collaborative Participation Agreement';
			}
			
			if (label === 'NegotiationNoAssist') {
				label = 'Negotiation';
			}
			
			if (label === 'AssistedNegotiation') {
				label = 'Negotiation';
			}
			
			if (label === 'MediationArbitration') {
				label = 'final Mediation Arbitration';
			}
			
			if (label === 'Mediation') {
			label = 'final Mediation';
			}
			
			if (label === 'FamilyCase') {
				label = 'Family Case Conference';
			}
			
			if (label === 'JudicialCase') {
				label = 'Judicial Case Conference';
			}
			
			if (label === 'Settlement') {
				label = 'Settlement Conference';
			}
			

			return label ? `Date of ${label}` : 'Date';
		},
		negotiationOptions() {
			let options = [];
			if (this.agreementData.legalHistory.provincialCourt) {
				options = [
					...options,
					{ text: 'Family Case Conference', value: 'FamilyCase' },
				];
			}
			if (this.agreementData.legalHistory.supremeCourt) {
				options = [
					...options,
					{ text: 'Judicial Case Conference', value: 'JudicialCase' },
					{ text: 'Settlement Conference', value: 'Settlement' },
				];
			}
			return options;
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
};
</script>

<template>
	<b-card v-if="!clientUserAgreement">
		<h4 slot="header" class="section-heading">Agreement Basics</h4>

		<radio-group
			v-model="agreement.negotiation"
			class="mb-3"
			input-cols="8"
			select
			label="How was this Agreement negotiated?"
			:options="[
				{
					text: 'Negotiation without the assistance of counsel',
					value: 'AssistedNegotiation',
				},
				{
					text: 'Negotiation with the assistance of counsel',
					value: 'NegotiationNoAssist',
				},
				{
					text: 'Collaborative Law Process',
					value: 'CollaborativeProcess',
				},
				
				{ text: 'Mediation', value: 'Mediation' },
				
				{
					text: 'Mediation Arbitration',
					value: 'MediationArbitration',
				},
				...negotiationOptions,
			]"
			:testid="'agreement-basics-how-negotiated'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mb-3"
			:condition="!isNegotiation"
			@reset="agreement.date = undefined"
		>
			<date-input
				v-model="agreement.date"
				:label="dateLabel"
				class="mb-3"
				:testid="'agreement-basics-date'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mb-3"
			:condition="agreement.negotiation === 'Mediation'"
			@reset="agreement.mediator = undefined"
		>
			<input-group
				v-model="agreement.mediator"
				input-cols="8"
				label="Name of Mediator"
				class="mb-3"
				:testid="'agreement-basics-mediator-name'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mb-3"
			:condition="agreement.negotiation === 'MediationArbitration'"
			@reset="agreement.mediator = undefined"
		>
			<input-group
				v-model="agreement.mediator"
				input-cols="8"
				label="Name of Mediator"
				class="mb-3"
				:testid="'agreement-basics-mediator-name-arbitration'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="agreement.type"
			class="mb-3"
			input-cols="4"
			label="Type of Agreement"
			:options="['Final', 'Interim']"
			:testid="'agreement-basics-agreement-type'"
			:disabled="isViewMode"
		/>
		<additional-clauses v-model="agreement.additionalClauses" :testid="'agreement-basics-add-clause-'"  :disabled="isViewMode"/>
	</b-card>
</template>
