<script>
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-parenting')
		};
		
	},
	computed: {
		parenting() {
			return this.agreementData.parenting;
			// return this.agreementData.parenting =  this.agreementData.parenting.hasOwnProperty('additionalClausesParentingMattersToRecitals') 
			// 	? this.agreementData.parenting 
			// 	: {...this.agreementData.parenting, additionalClausesParentingMattersToRecitals: []};;
		},
		parentingMatterTravelAreaOptions() {
			const options = ['Canada'];
			if (this.agreementData.agreement.updatedProvinceOfAgreement) {
				options.unshift(this.agreementData.agreement.updatedProvinceOfAgreement);
			}
			return options;
		}
	},
	mounted(){
		this.agreementData.parenting =  this.agreementData.parenting.hasOwnProperty('additionalClausesParentingMattersToRecitals') 
			? this.agreementData.parenting 
			: {...this.agreementData.parenting, additionalClausesParentingMattersToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Other Parenting Matters</h4>

		<yes-no-choice
			v-model="parenting.travelClause"
			class="mb-3"
			label="Would you like to include standard travel provisions?"
			reset-undefined
			:testid="'parenting-matter-include-standard-provision'"
			:disabled="isViewMode"
		/>

		<conditional-group class="my-3" :condition="parenting.travelClause">
			<radio-group
				v-model="parenting.partnerHoldingPassport"
				input-cols="2"
				partner
				:options="['Either']"
				select
				class="mb-3"
				:agreement-data="agreementData"
				label="What parent will hold the passport?"
				:testid="'parenting-matter-passport-holder'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="parenting.travelInformArea"
				input-cols="4"
				class="mb-3"
				label="The parties will inform one another regarding details of travel if they intend to travel during their parenting time with the children outside of what area"
				other
				select
				:options="parentingMatterTravelAreaOptions"
				:testid="'parenting-matter-travel-inform-area'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="parenting.travelConsultArea"
				input-cols="4"
				class="mb-3"
				label="The parties will consult one another regarding details of travel if they intend to travel during their parenting time with the children outside of what area"
				other
				select
				:options="parentingMatterTravelAreaOptions"
				:testid="'parenting-matter-travel-consult-area'"
				:length="'long'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<radio-group
			v-model="parenting.newPartnerIntroduce"
			input-cols="4"
			class="mb-3"
			label="Prior to introducing the children to new romantic partners, will the parties inform or consult one another?"
			select
			:options="[
				{ value: 'inform', text: 'Inform' },
				{ value: 'consult', text: 'Consult' },
				{ value: false, text: 'No specification' },
			]"
			:testid="'parenting-matter-new-partner-introduction-to-children'"
			:disabled="isViewMode"
		/>

		<radio-group
			v-model="parenting.newPartnerCohabitate"
			input-cols="4"
			class="mb-3"
			label="Prior to cohabiting with new romantic partners, will the parties inform or consult one another?"
			select
			:options="[
				{ value: 'inform', text: 'Inform' },
				{ value: 'consult', text: 'Consult' },
				{ value: false, text: 'No specification' },
			]"
			:testid="'parenting-matter-new-partner-cohabiting'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.nestingArrangement === false"
		>
			<yes-no-choice
				v-model="parenting.separateSets"
				class="mb-3"
				label="Will the parties maintain separate sets of Children's items in each home?"
				reset-undefined
				:testid="'parenting-matter-separate-sets'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="parenting.annualReview"
			class="mb-3"
			label="Will the parties perform an annual review of the Parenting Plan?"
			reset-undefined
			:testid="'parenting-matter-annual-review'"
			:disabled="isViewMode"
		/>
		<additional-clauses
			:title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2"
			v-model="parenting.additionalClausesParentingMattersToRecitals" :testid="'parenting-matter-add-clause-to-recitals-'"
			:disabled="isViewMode"
		/>
		<additional-clauses
			:title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2"
			v-model="parenting.additionalClausesParentingMatters" :testid="'parenting-matter-add-clause-'"
			:disabled="isViewMode"
		/>
	</b-card>
</template>
