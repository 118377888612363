<script>
import { mapState } from 'vuex';
import { BIconCloudUpload, BIconSearch } from 'bootstrap-vue';

import authenticatedRequest from '@/lib/authenticatedRequest';
import { storage, auth } from '../firebase';

import PDFPreview from './PDFPreview.vue';
import File from './File.vue';

function getFileTypes() {
	const fileTypes = [
		{ id: 'income-tax-0', type: 'Partner 1 Income Tax' },
		{ id: 'income-tax-1', type: 'Partner 2 Income Tax' },
		{ id: 'notice-of-assesment', type: 'Notice Of Assesment' },
		{ id: 'pay-stubs-0', type: 'Partner 1 Pay Stubs' },
		{ id: 'pay-stubs-1', type: 'Partner 2 Pay Stubs' },
		{ id: 'corp-income-tax', type: 'Corporate Income Tax' },
		{
			id: 'corp-financial-statements',
			type: 'Corporate Financial Statements',
		},
		{ id: 'account', type: 'Account X' },
	];
	return fileTypes;
}

export default {
	name: 'Documents',
	components: {
		File,
		BIconCloudUpload,
		BIconSearch,
		'pdf-preview': PDFPreview,
	},

	computed: {
		...mapState(['agreement', 'user']),
	},
	data() {
		const fileTypes = getFileTypes();

		return {
			files: [],
			folders: [],
			error: '',
			pageLoading: true,
			loading: false,
			preview: false,
			previewFile: null,
			uploadFileType: '',
			showFolderType: 'income-tax-0',
			fileTypes,
			shownFiles: [],
		};
	},
	async mounted() {
		await auth.currentUser.getIdToken(true);
		await this.getFiles();
	},
	methods: {
		async getFiles() {
			try {
				const response = await storage
					.ref()
					.child(`agreements`)
					.child(this.agreement.id)
					.listAll();
				this.files = response.items;
				this.folders = response.prefixes;
				await Promise.all(
					this.folders.map(async (ref, index) => {
						const folderResponse = await ref.listAll();
						this.folders[index].items = folderResponse.items;
						// ? folderResponse.items.map(async item => {
						// 	console.log(item)
						// 	const ref = storage.ref().child(item.fullPath)
						// 	console.log(ref)
						// 	return await ref.getMetadata();
						// }): [];
					})
				);
				this.showFolder(this.showFolderType);
				this.pageLoading = false;
				console.log(this.files, this.folders);
			} catch (error) {
				console.error(error);
			}
		},
		async fileUpload(file) {
			const agreementStorageRef = storage
				.ref()
				.child(`agreements`)
				.child(this.agreement.id)
				.child(this.uploadFileType)
				.child(file.name);
			const metaData = {
				customMetadata: {
					name: file.name,
					size: file.size,
					type: file.type,
					userId: this.user.uid,
				},
			};

			try {
				await agreementStorageRef.put(file, metaData);
				// this.files = [...this.files, test.ref];
				await this.getFiles();
			} catch (error) {
				console.error(error);
				this.error = 'Something went wrong';
			}
			this.uploadFileType = '';
		},
		async createFolder() {
			// Doesn't work yet
			const folderName = 'qwer';
			storage
				.ref()
				.child(`agreements`)
				.child(this.agreement.id)
				.child(folderName);
		},
		async getDownloadURL(file) {
			try {
				return await file.getDownloadURL();
			} catch (error) {
				switch (error.code) {
					case 'storage/object-not-found':
						// File doesn't exist
						break;
					case 'storage/unauthorized':
						// User doesn't have permission to access the object
						break;
					case 'storage/canceled':
						// User canceled the upload
						break;

					case 'storage/unknown':
						// Unknown error occurred, inspect the server response
						break;
				}
			}
		},
		async download(file) {
			const url = await this.getDownloadURL(file);
			window.open(url, '_blank');
		},
		async deleteFile(file) {
			try {
				this.loading = true;
				await file.delete();
				await this.getFiles();
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		},
		async renameFile(file, fileName) {
			// not fully functional yet
			try {
				const { data } = await authenticatedRequest('POST', '/rename', {
					fileName: file.fullPath,
					newFileName: `${file.parent.fullPath}/${fileName}`,
					uid: this.user.uid,
					agreementId: this.agreement.id,
				});
				console.log(data);
				return data;
			} catch (error) {
				console.error(error);
			}
		},
		async showPreview(file) {
			this.preview = true;
			const url = await this.getDownloadURL(file);
			console.log(url);
			this.previewFile = url;
		},
		hideModal() {
			this.preview = false;
			this.previewFile = null;
		},
		uploadType(fileType) {
			this.uploadFileType = fileType;
			document.getElementById('file-upload').click();
		},
		showFolder(fileType) {
			this.showFolderType = fileType;
			const selectedFolder =
				this.folders && this.folders.length
					? this.folders.find(
							({ name }) => name === this.showFolderType
					  )
					: null;

			this.shownFiles =
				selectedFolder && selectedFolder.items
					? selectedFolder.items
					: [];
		},
		countItemsInFolder(folderType) {
			const selectedFolder = this.folders.find(
				({ name }) => name === folderType
			);

			return selectedFolder && selectedFolder.items
				? selectedFolder.items.length
				: 0;
		},
	},
};

// 3 income tax
// 3 notice of assesment
// 3 pay stubs
// 3 corp income tax
// 3 corp financial statements
// 1 per account something listing what is in the account

// Rename Files file-rename
// Folder creation
// Preview Pdfs pdf-preview
</script>

<template>
	<b-container>
		<pdf-preview v-if="preview" :file="previewFile" :preview="preview" />

		<b-row class="my-4">
			<b-col>
				<b-card-group columns>
					<fragment v-for="fileType in fileTypes" :key="fileType.id">
						<b-card :header="fileType.type" class="text-center">
							<div v-if="pageLoading">
								<h4 slot="header">
									<b-skeleton
										width="25%"
										animation="wave"
									></b-skeleton>
								</h4>
								<div class="mb-5">
									<b-skeleton
										width="85%"
										animation="wave"
									></b-skeleton>
									<b-skeleton
										width="55%"
										animation="wave"
									></b-skeleton>
									<b-skeleton
										width="70%"
										animation="wave"
									></b-skeleton>
								</div>
							</div>
							<div v-else>
								<b-card-text>
									{{
										countItemsInFolder(fileType.id) === 6
											? 'All Expected Files Uploaded'
											: `${countItemsInFolder(
													fileType.id
											  )}/6 Files Expected`
									}}
								</b-card-text>
								<b-button-group>
									<b-button
										variant="primary"
										@click="uploadType(fileType.id)"
										><b-icon-cloud-upload
									/></b-button>
									<b-button
										variant="secondary"
										@click="showFolder(fileType.id)"
										><b-icon-search
									/></b-button>
								</b-button-group>
							</div>
						</b-card>
					</fragment>
				</b-card-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col xs="12" sm="8" md="4">
				<b-card tag="article" class="mb-2">
					<b-row class="text-center">
						<b-col>
							<b-card-title>{{
								fileTypes.find(
									({ id }) => id === showFolderType
								).type
							}}</b-card-title>
							<!-- <b-link @click="createFolder">Create folder</b-link> -->
						</b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col xs="12" sm="12" md="8">
				<div v-if="shownFiles.length > 0">
					<div v-for="file in shownFiles" :key="file.name">
						<file
							:file="file"
							:download="download"
							:delete-file="deleteFile"
							:rename-file="renameFile"
							:show-preview="showPreview"
							:loading="loading"
						/>
					</div>
				</div>
				<b-card v-else>
					<b-row>
						<b-col>
							<b>No Files</b>
						</b-col>
					</b-row>
				</b-card>

				<!-- <b-card
					v-for="folder in folders"
					:key="folder.name"
					tag="article"
					class="mb-2"
				>
					<b-row>
						<b-col>
							<b>{{ folder.name }}</b>
						</b-col>
					</b-row>
				</b-card> -->
				<b-card style="display: none">
					<b-row>
						<b-form-file
							id="file-upload"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							accept="application/pdf"
							@input="fileUpload"
						></b-form-file>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>
