<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState(['activeUser', 'user', 'agreement']),
		show() {
			return this.activeUser.user != this.user.uid;
		},
	},
	mounted() {
		window.addEventListener('beforeunload', (e) => {
			e.preventDefault();
			if (this.activeUser.user == this.user.uid) {
				this.$store.dispatch('clearActiveUser', this.agreement.id);
			}
		});
	},
};
</script>

<template>
	<div v-if="activeUser && activeUser.user">
		<!-- <b-modal
			id="activeuser"
			v-model="show"
			hide-footer
			hide-header
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			centered
		>
			<div class="p-2">
				<h4>Oops! You’re not alone!</h4>
				<div>Someone else is currently working on this agreement.</div>
                <div>Ask your colleague to exit this client file in order to continue.</div>
				<hr />
				Return to <b-link :to="{ name: 'home' }">Client Files</b-link>
			</div>
		</b-modal> -->
	</div>
</template>
