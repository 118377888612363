<script>
import pdf from 'vue-pdf';
import { BIconArrowLeftSquare, BIconArrowRightSquare } from 'bootstrap-vue';
// https://github.com/FranckFreiburger/vue-pdf/pull/350

export default {
	name: 'PdfPreview',
	components: { pdf, BIconArrowRightSquare, BIconArrowLeftSquare },
	props: ['file', 'preview'],
	data() {
		return {
			numberOfPages: undefined,
			currentPage: 1,
			// src: loadingTask,
			rotate: 0,
		};
	},
	mounted() {
		// this.src.promise.then((pdf) => {
		// 	this.numberOfPages = pdf.numPages;
		// });
	},
	methods: {
		handleError(error) {
			console.error(error);
		},
		getFile() {
			return pdf.createLoadingTask(this.file);
		},
		getNumberOfPages(numberOfPages) {
			console.log(numberOfPages);
			this.numberOfPages = numberOfPages;
		},
		pageNext() {
			this.currentPage = this.currentPage + 1;
		},
		pageback() {
			this.currentPage = this.currentPage - 1;
		},
	},
};
</script>

<template>
	<b-modal
		id="previewModal"
		v-model="preview"
		size="xl"
		scrollable
		hide-header
		@hide="hideModal"
	>
		<pdf
			:src="file"
			:page="currentPage"
			@num-pages="getNumberOfPages"
			@error="handleError"
		></pdf>
		<template #modal-footer="">
			<b-button-group>
				<b-button
					variant="success"
					:disabled="currentPage === 1"
					@click="pageBack"
				>
					<b-icon-arrow-left-square />
				</b-button>
				<b-button
					variant="success"
					:disabled="currentPage === numberOfPages"
					@click="pageNext"
				>
					<b-icon-arrow-right-square />
				</b-button>
			</b-button-group>
			<b-button>close</b-button>
		</template>
	</b-modal>

	<!-- <pdf
			v-for="i in numberOfPages"
			:key="i"
			:src="src"
			:page="i"
			@error="handleError"
			:rotate="rotate"
		></pdf> -->
</template>
