var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"section-heading",attrs:{"slot":"header"},slot:"header"},[_vm._v("Medical Coverage")]),_c('yes-no-choice',{staticClass:"mb-2",attrs:{"reset-undefined":"","label":("Will " + _vm.partner1Name + " cover any dependants on a medical plan?"),"testid":'medical-p1-dependants',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.medicalCoveragePartner1),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner1", $$v)},expression:"supportAdditionals.medicalCoveragePartner1"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":_vm.supportAdditionals.medicalCoveragePartner1}},[_c('radio-group',{staticClass:"mb-3",attrs:{"agreement-data":_vm.agreementData,"input-cols":"4","label":("Will " + _vm.partner1Name + " be maintaining a current plan or obtaining a new plan?"),"reset-undefined":"","select":"","options":[
				{ text: 'Maintain', value: 'maintain' },
				{ text: 'Obtain', value: 'obtain' } ],"testid":'medical-p1-maintain-or-obtain',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.medicalCoveragePartner1Plan),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner1Plan", $$v)},expression:"supportAdditionals.medicalCoveragePartner1Plan"}}),_c('radio-group',{attrs:{"agreement-data":_vm.agreementData,"input-cols":"4","label":"Who is the beneficiary of this medical coverage?","reset-undefined":"","select":"","options":_vm.beneficiaryOptions(_vm.partner2Name),"testid":'medical-p1-beneficiary',"length":'long',"disabled":_vm.isViewMode},model:{value:(
					_vm.supportAdditionals.medicalCoveragePartner1Beneficiary
				),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner1Beneficiary", $$v)},expression:"\n\t\t\t\t\tsupportAdditionals.medicalCoveragePartner1Beneficiary\n\t\t\t\t"}})],1),_c('yes-no-choice',{staticClass:"mb-2",attrs:{"reset-undefined":"","label":("Will " + _vm.partner2Name + " cover any dependants on a medical plan?"),"testid":'medical-p2-dependants',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.medicalCoveragePartner2),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner2", $$v)},expression:"supportAdditionals.medicalCoveragePartner2"}}),_c('conditional-group',{staticClass:"my-3",attrs:{"condition":_vm.supportAdditionals.medicalCoveragePartner2}},[_c('radio-group',{staticClass:"mb-3",attrs:{"agreement-data":_vm.agreementData,"input-cols":"4","label":("Will " + _vm.partner2Name + " be maintaining a current plan or obtaining a new plan?"),"reset-undefined":"","select":"","options":[
				{ text: 'Maintain', value: 'maintain' },
				{ text: 'Obtain', value: 'obtain' } ],"testid":'medical-p2-maintain-or-obtain',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.medicalCoveragePartner2Plan),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner2Plan", $$v)},expression:"supportAdditionals.medicalCoveragePartner2Plan"}}),_c('radio-group',{attrs:{"agreement-data":_vm.agreementData,"input-cols":"4","label":"Who is the beneficiary of this medical coverage?","reset-undefined":"","select":"","options":_vm.beneficiaryOptions(_vm.partner1Name),"testid":'medical-p2-beneficiary',"length":'long',"disabled":_vm.isViewMode},model:{value:(
					_vm.supportAdditionals.medicalCoveragePartner2Beneficiary
				),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "medicalCoveragePartner2Beneficiary", $$v)},expression:"\n\t\t\t\t\tsupportAdditionals.medicalCoveragePartner2Beneficiary\n\t\t\t\t"}})],1),_c('additional-clauses',{staticClass:"mt-3",attrs:{"title":'Additional Clauses into Recitals',"newLabel":'Add Clause to Recitals',"testid":'medical-add-clause-to-recitals-',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.additionalClausesMedicalCoverageToRecitals),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "additionalClausesMedicalCoverageToRecitals", $$v)},expression:"supportAdditionals.additionalClausesMedicalCoverageToRecitals"}}),_c('additional-clauses',{staticClass:"mt-2",attrs:{"title":'Additional Clauses into Sections',"newLabel":'Add Clause to Section',"testid":'medical-add-clause-',"disabled":_vm.isViewMode},model:{value:(_vm.supportAdditionals.additionalClausesMedicalCoverage),callback:function ($$v) {_vm.$set(_vm.supportAdditionals, "additionalClausesMedicalCoverage", $$v)},expression:"supportAdditionals.additionalClausesMedicalCoverage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }