<script>
import {
	BIconCloudDownload,
	BIconTrash,
	// BIconPencil
} from 'bootstrap-vue';

export default {
	components: {
		BIconCloudDownload,
		BIconTrash,
		// BIconPencil
	},
	props: [
		'file',
		'download',
		'deleteFile',
		'loading',
		'renameFile',
		'showPreview',
	],
	data() {
		return {
			editing: false,
			fileName: '',
			updating: false,
		};
	},
	methods: {
		editFile() {
			this.editing = true;
			this.fileName = this.file.name;
		},
		async saveFile() {
			this.updating = true;
			await this.renameFile(this.file, this.fileName);
			this.editing = false;
			this.fileName = '';
			this.updating = false;
		},
		cancelEdit() {
			this.editing = false;
			this.fileName = '';
		},
	},
};
</script>
<template>
	<b-card tag="article" class="mb-2">
		<b-row align-h="end">
			<b-col class="mr-auto" cols="auto">
				<div v-if="editing">
					<b-row no-gutters>
						<b-col cols="8">
							<b-input
								v-model="fileName"
								size="sm"
								label="File Name"
								:disabled="updating"
							></b-input>
						</b-col>
						<b-col cols="4">
							<b-button
								size="sm"
								variant="success"
								:disabled="updating"
								@click="saveFile"
								>Save</b-button
							>
							<b-button
								size="sm"
								variant="secondary"
								:disabled="updating"
								@click="cancelEdit"
								>Cancel</b-button
							>
						</b-col>
					</b-row>
				</div>
				<b-col style="cursor: pointer" @dblclick="showPreview(file)">
					<b>{{ file.name }}</b>
				</b-col>
			</b-col>
			<b-col cols="auto">
				<b-button-group size="sm">
					<!-- <b-button
						variant="outline-primary"
						:disabled="loading"
						@click="editFile(file)"
					>
						<b-icon-pencil />
					</b-button> -->
					<b-button
						variant="outline-primary"
						:disabled="loading"
						@click="download(file)"
						data-testid="download-file"
					>
						<b-icon-cloud-download />
					</b-button>
					<b-button
						variant="outline-danger"
						:disabled="loading"
						@click="deleteFile(file)"
						data-testid="delete-file"
					>
						<b-icon-trash />
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
	</b-card>
</template>
