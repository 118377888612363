<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	mixins:[validationMixin, agreementValidation],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed:{
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
			// return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},
	validations:{
		agreementData:{
			children:{
				$each: {
					name: { 
						first:{
							required
						},
						last:{
							required
						}
					}, 
					birthdate:{
						required
					},
					pronouns:{
						required
					}
				},
			}
		}
	},
	methods:{
		triggerValidation(){
			this.agreementData.children.map((child,index)=>{
				this.$refs[`child-input-${index}`].triggerValidation()
			})
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Child Information</h4>

		<list-item
			v-slot="{ item: child, index:index }"
			v-model="agreementData.children"
			class="mb-5"
			new-label="Add Child"
			:object-prototype="
				() => {
					return { name: {
							first:'',
							last:'',
						},
						birthdate:'',
						pronouns:null
					};
				}
			"
			:testid="'child-'"
			:disabled="isViewMode"
		>
			<child-input-group v-model="child" :testid="'child-'+index" :ref="'child-input-'+index" :disabled="isViewMode"/>
		</list-item>
		<additional-clauses
			v-if="!clientUserAgreement"
			v-model="agreementData.childrenDetails.additionalClauses" :testid="'child-add-clause-'"
			:disabled="isViewMode"
		/>
	</b-card>
</template>
