<script>
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		cpp() {
			return this.agreementData.cpp
			// return this.agreementData.cpp = this.agreementData.cpp.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.cpp 
			// 	: {...this.agreementData.cpp, additionalClausesToRecitals: []};
		
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	mounted(){
		this.agreementData.cpp = this.agreementData.cpp.hasOwnProperty('additionalClausesToRecitals') ? this.agreementData.cpp : {...this.agreementData.cpp, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card v-if="!clientUserAgreement">
		<h4 slot="header" class="section-heading">CPP</h4>

		<radio-group
			v-model="cpp.plan"
			reset-undefined
			select
			input-cols="5"
			class="mb-3"
			label="Will the parties equalize or keep the Canada Pension Plan credits accumulated by each of them during their relationship?"
			:options="[
				{ text: 'Keep their own CPP credits', value: 'keep' },
				{ text: 'Equalize their CPP credits', value: 'equalize' },
			]"
			:testid="'CPP-equalization'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="cpp.additionalClausesToRecitals" :testid="'CPP-equalization-to-recitals-'"
			:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="cpp.additionalClauses" :testid="'CPP-equalization-'"
			:disabled="isViewMode"/>
	</b-card>
</template>
