<script>
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed: {
		legalHistory() {
			return this.agreementData.legalHistory;
		},
		relationshipStatus() {
			return this.agreementData.relationship.status;
		},
		filteredOptions(){
			const options=[
					'Parenting',
					'Interim Child Support',
					'Interim Spousal Support',
					'Interim occupancy of the Family Residence',
					'Interim financial management for each of the parties',
				]
			return options.filter((option)=>{
				if(option === 'Parenting'){
					return this.agreementData.agreement.sections.includes('Parenting') && this.agreementData.children.length > 0
				}
				if(option === 'Interim Child Support'){
					return this.agreementData.agreement.sections.includes('Child Support') && this.agreementData.children.length > 0
				}
				if(option === 'Interim Spousal Support'){
					return this.agreementData.agreement.sections.includes('Spousal Support')
				}
				if(option === 'Interim occupancy of the Family Residence'){
					return this.agreementData.agreement.propertyRecitals.includes('Family Residence')
				}
				return true
			})
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
};
</script>

<template>
	<b-card v-if="!clientUserAgreement">
		<h4 slot="header" class="section-heading">Legal History of Parties</h4>

		<conditional-group
			class="mb-3"
			:condition="
				agreementData.relationship.status ===
				'cohabited for over two years'
			"
		>
			<yes-no-choice
				v-model="legalHistory.cohabitationAgreement"
				reset-undefined
				label="Did the parties enter into a Cohabitation Agreement?"
				class="mb-3"
				:testid="'legal-history-cohabitation-agreement'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mb-3"
			:condition="legalHistory.cohabitationAgreement"
		>
			<date-input
				v-model="legalHistory.cohabitationAgreementDate"
				reset-undefined
				label="Date of Agreement"
				class="mb-3"
				:testid="'legal-history-agreement-date'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mb-3"
			:condition="agreementData.relationship.status === 'married'"
		>
			<yes-no-choice
				v-if="relationshipStatus == 'Married'"
				v-model="legalHistory.marriageAgreement"
				reset-undefined
				label="Did the parties enter into a Marriage Agreement?"
				class="mb-3"
				:testid="'legal-history-marriage-agreement'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<conditional-group
			class="mb-3"
			:condition="legalHistory.marriageAgreement"
		>
			<date-input
				v-if="relationshipStatus == 'Married'"
				v-model="legalHistory.marriageAgreementDate"
				reset-undefined
				label="Date of Agreement"
				class="mb-3"
				:testid="'legal-history-date'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="legalHistory.interimAgreement"
			reset-undefined
			label="Did the parties enter into an Interim Separation Agreement?"
			class="mb-3"
			:testid="'legal-history-interim-separation-agreement'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mb-3"
			:condition="legalHistory.interimAgreement"
		>
			<radio-group
				v-model="legalHistory.interimAgreementTreatment"
				reset-undefined
				select
				class="mb-3"
				input-cols="4"
				label="How will this agreement affect the previous agreement?"
				:options="[
					{ text: 'Replace', value: 'replaces' },
					{ text: 'Vary', value: 'varies' },
					{ text: 'Supplement', value: 'supplements' },
				]"
				:testid="'legal-history-previous-agreement-effect'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<date-input
				v-model="legalHistory.interimAgreementDate"
				reset-undefined
				label="Date of Agreement"
				class="mb-3"
				:testid="'legal-history-interim-agreement-date'"
				:disabled="isViewMode"
			/>

			<check-group
				v-model="legalHistory.interimAgreementContents"
				reset-undefined
				class="mb-3"
				label="What matters were included in the past interim separation agreement?"
				:options="filteredOptions"
				:testid="'legal-history-interim-agreement-contents'"
				:id="'legal-history-interim-agreement-contents'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="mb-3"
				:condition="
					legalHistory.interimAgreementContents &&
					legalHistory.interimAgreementContents.includes(
						'Interim Spousal Support'
					)
				"
			>
				<radio-group
					v-model="
						legalHistory.interimAgreementSpousalSupportRecipient
					"
					input-cols="4"
					partner
					select
					class="mb-3"
					reset-undefined
					label="The interim separation agreement provided support for:"
					:agreement-data="agreementData"
					:testid="'legal-history-interim-support'"
					:length="'long'"
					:disabled="isViewMode"
				/>
			</conditional-group>
		</conditional-group>

		<yes-no-choice
			v-model="legalHistory.provincialCourt"
			reset-undefined
			label="Have court proceedings commenced in Provincial Court?"
			class="mb-3"
			:testid="'legal-history-provincial-court-cmmencement'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mb-3"
			:condition="legalHistory.provincialCourt"
		>
			<!-- <radio-group
				v-model="legalHistory.provincialCourtApplicant"
				reset-undefined
				select
				partner
				input-cols="4"
				class="mb-3"
				label="Which party filed the Provincial Court documents?"
				:agreement-data="agreementData"
				:testid="'legal-history-provincial-court-documents'"
			/> -->

			<!-- <radio-group
				v-model="legalHistory.provincialCourtCommencedDocument"
				reset-undefined
				input-cols="6"
				class="mb-3"
				label="What document commenced action?"
				other
				select
				:options="[
					{
						text: 'Application to Obtain an Order',
						value: 'applicationToObtainOrder',
					},
					{ text: 'Consent Order', value: 'consentOrder' },
					{ text: 'Agreement', value: 'agreement' },
				]"
				:testid="'legal-history-document-commenced-action'"
			/> -->

			<input-group
				v-model="legalHistory.provincialCourtCity"
				reset-undefined
				input-cols="4"
				label="What city were the court proceedings registered in?"
				class="mb-3"
				placeholder="ie: Victoria"
				:testid="'legal-history-provincial-court-city'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="legalHistory.provincialCourtFileNumber"
				reset-undefined
				input-cols="4"
				label="Provincial Court File Number"
				class="mb-3"
				:testid="'legal-history-court-file-name'"
				:disabled="isViewMode"
			/>

			<!-- <radio-group
				v-model="legalHistory.provincialCourtRespondingDocument"
				reset-undefined
				input-cols="4"
				label="Responding Document"
				select
				class="mb-3"
				other
				:options="[{ text: 'Reply', value: 'Reply' }]"
				:testid="'legal-history-court-responding-document'"
			/> -->
		</conditional-group>

		<yes-no-choice
			v-model="legalHistory.supremeCourt"
			reset-undefined
			label="Have court proceedings commenced in Supreme Court?"
			class="mb-3"
			:testid="'legal-history-supreme-court-commencement'"
			:disabled="isViewMode"
		/>

		<conditional-group class="mb-3" :condition="legalHistory.supremeCourt">
			<!-- <radio-group
				v-model="legalHistory.supremeCourtApplicant"
				select
				partner
				input-cols="4"
				reset-undefined
				class="mb-3"
				label="Which party filed the Supreme Court documents?"
				:agreement-data="agreementData"
				:testid="'legal-history-supreme-court-documents-filed-by'"
			/> -->

			<!-- <radio-group
				v-model="legalHistory.supremeCourtCommencedDocument"
				reset-undefined
				input-cols="8"
				class="mb-3"
				label="What document commenced action?"
				other
				select
				:options="[
					{
						text: 'Notice of Family Claim',
						value: 'noticeOfFamilyClaim',
					},
					{
						text: 'Writ of Summons and Statement of Claim',
						value: 'statementOfClaim',
					},
					{ text: 'Consent Order', value: 'consentOrder' },
				]"
				:testid="'legal-history-supreme-court-commenced-action'"
			/> -->

			<input-group
				v-model="legalHistory.supremeCourtCity"
				reset-undefined
				input-cols="4"
				label="What city were the court proceedings registered in?"
				class="mb-3"
				placeholder="ie: Victoria"
				:testid="'legal-history-supreme-court-city'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="legalHistory.supremeCourtFileNumber"
				reset-undefined
				input-cols="4"
				label="Supreme Court File Number"
				class="mb-3"
				:testid="'legal-history-supreme-court-file-number'"
				:disabled="isViewMode"
			/>

			<!-- <radio-group
				v-model="legalHistory.supremeCourtRespondingDocument"
				reset-undefined
				input-cols="8"
				label="Responding Document"
				select
				class="mb-3"
				other
				:options="[
					{
						text: 'Response to Family Claim',
						value: 'responseToFamilyClaim',
					},
					{
						text: 'Appearance and Response',
						value: 'appearanceAndResponse',
					},
					{
						text: 'Appearance, Response and Counterclaim',
						value: 'appearanceReponseAndCounterclaim',
					},
				]"
				:testid="'legal-history-supreme-court-responding-document'"
			/> -->
		</conditional-group>
		<additional-clauses v-model="legalHistory.additionalClauses" :testid="'legal-history-add-clause-'"  :disabled="isViewMode"/>
	</b-card>
</template>
