import shortid from 'shortid';

let p1EventNo = shortid.generate();

const weekOnOff = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 7, 15, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 7, 15, 1),
		end: new Date(2022, 0, 14, 15, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 14, 15, 0),
		end: new Date(2022, 0, 15, 23, 59),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];

const twoTwoThree = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 3, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 3, 14, 0),
		end: new Date(2022, 0, 5, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 5, 14, 0),
		end: new Date(2022, 0, 7, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 7, 14, 0),
		end: new Date(2022, 0, 10, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 10, 14, 0),
		end: new Date(2022, 0, 12, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 12, 14, 0),
		end: new Date(2022, 0, 14, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 14, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];
const twoTwoFiveFive = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 3, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 3, 14, 0),
		end: new Date(2022, 0, 5, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 5, 14, 0),
		end: new Date(2022, 0, 7, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 7, 14, 0),
		end: new Date(2022, 0, 12, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 12, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];
const threeFourThreeFour = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 5, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 5, 14, 0),
		end: new Date(2022, 0, 8, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 8, 14, 0),
		end: new Date(2022, 0, 12, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 12, 14, 0),
		end: new Date(2022, 0, 15, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 15, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];
const threeThreeFourFour = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 5, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 5, 14, 0),
		end: new Date(2022, 0, 8, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 8, 14, 0),
		end: new Date(2022, 0, 11, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 11, 14, 0),
		end: new Date(2022, 0, 15, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 15, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];
const everyWKD = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 3, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 3, 14, 0),
		end: new Date(2022, 0, 7, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 7, 14, 0),
		end: new Date(2022, 0, 10, 14, 0),
		class: 'partner1',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 10, 14, 0),
		end: new Date(2022, 0, 14, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 14, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];
const everyOtherWKD = [
	{
		start: new Date(2022, 0, 2, 0, 0),
		end: new Date(2022, 0, 7, 14, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
	{
		start: new Date(2022, 0, 7, 14, 0),
		end: new Date(2022, 0, 10, 14, 0),
		class: 'partner2',
		eventNo: shortid.generate(),
	},
	{
		start: new Date(2022, 0, 10, 14, 0),
		end: new Date(2022, 0, 15, 24, 0),
		class: 'partner1',
		eventNo: p1EventNo,
	},
];

const schedules = {
	weekOnOff,
	twoTwoThree,
	twoTwoFiveFive,
	threeFourThreeFour,
	threeThreeFourFour,
	everyWKD,
	everyOtherWKD,
};
const options = [
	{
		id: 'weekOnOff',
		label: 'Week On/Off',
	},
	{
		id: 'twoTwoThree',
		label: '2/2/3',
	},
	{
		id: 'everyOtherWKD',
		label: 'Every Other Weekend',
	},
	{
		id: 'twoTwoFiveFive',
		label: '2/2/5/5',
	},
	{
		id: 'threeFourThreeFour',
		label: '3/4/3/4',
	},
	{ id: 'threeThreeFourFour', label: '3/3/4/4' },
	{ id: 'everyWKD', label: 'Every Weekend' },
];
export { options };

export default schedules;
