var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"section-heading",attrs:{"slot":"header"},slot:"header"},[_vm._v("Child Information")]),_c('list-item',{staticClass:"mb-5",attrs:{"new-label":"Add Child","object-prototype":function () {
				return { name: {
						first:'',
						last:'',
					},
					birthdate:'',
					pronouns:null
				};
			},"testid":'child-',"disabled":_vm.isViewMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var child = ref.item;
			var index = ref.index;
return [_c('child-input-group',{ref:'child-input-'+index,attrs:{"testid":'child-'+index,"disabled":_vm.isViewMode},model:{value:(child),callback:function ($$v) {child=$$v},expression:"child"}})]}}]),model:{value:(_vm.agreementData.children),callback:function ($$v) {_vm.$set(_vm.agreementData, "children", $$v)},expression:"agreementData.children"}}),(!_vm.clientUserAgreement)?_c('additional-clauses',{attrs:{"testid":'child-add-clause-',"disabled":_vm.isViewMode},model:{value:(_vm.agreementData.childrenDetails.additionalClauses),callback:function ($$v) {_vm.$set(_vm.agreementData.childrenDetails, "additionalClauses", $$v)},expression:"agreementData.childrenDetails.additionalClauses"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }