import pureFilters from '@/lib/agreement/filters';

export default {
	computed: {
		childNameOrChildren() {
			const children = this.agreementData.children;

			return children.length === 1
				? pureFilters.preferredName(children[0])
				: 'the Children';
		},
	},
};
